const CustomToolTip = ({ active, payload }: { active?: boolean; payload?: any[] }) => {
    if (active && payload && payload.length) {
        const data = payload[0].payload; // Acceder al objeto del payload
        return (
            <div className="custom-tooltip bg-primary-100 border border-[#CCCCCC] p-2 text-bmedium">
                <p className="font-bold text-center">Datos del año {data.name}</p>
                {
                    // Iterar sobre las entradas del objeto para mostrar todas las propiedades dinámicamente
                    Object.entries(data).map(([key, value]) => {
                        // Si la clave contiene 'presupuesto', agregar formato de puntos al valor
                        const formattedValue = key.toLowerCase().includes('presupuesto')
                            ? Number(value).toLocaleString('es-ES') // Formato con separadores de miles
                            : Number(value).toLocaleString('es-ES');

                        return key !== 'name' && ( // Excluir 'name' si no quieres mostrarlo dos veces
                            <p key={key}>{`${key}: ${formattedValue}`}</p>
                        );
                    })
                }
            </div>
        );
    }
    return null; // No mostrar nada si no hay datos
};

export default CustomToolTip