import { getImageFormat } from '../../utils/utils';
import NotImg from './NotImg';

interface IImages {
    imagen: string;
    imagen_base64: string;
    className?: string; // className puede ser opcional
}

const Images = ({ imagen, imagen_base64, className = '' }: IImages) => {
    const format: string | null = getImageFormat(imagen);

    const isImageAvailable = imagen_base64 !== 'Archivo no encontrado' && imagen;
    const imageSrc = isImageAvailable ? `data:image/${format};base64,${imagen_base64}` : null;

    if (!imageSrc) {
        return null;
    }

    return (
        <img src={imageSrc} alt={imagen.toLowerCase()} className={className} />
    );
};

export default Images;