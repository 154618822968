import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
const { REACT_APP_API_PATH } = process.env;

export const getRights = createAsyncThunk(
    "rights/getRights",
    async (period: string | undefined, thunkAPI) => { // El parámetro `period` puede ser `undefined`
        try {
            // Construcción de la URL
            const url = period
                ? `${REACT_APP_API_PATH}/derechos?periodo=${period}`
                : `${REACT_APP_API_PATH}/derechos`;

            const response = await axiosInstance.get(url);
            const data = response.data;

            return {
                rights: data.data,
            };
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response?.data || error.message);
        }
    }
);