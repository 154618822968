import axios from 'axios';

const apiUrl = process.env.REACT_APP_API_PATH

const axiosInstance = axios.create({
    baseURL: apiUrl,
});

axiosInstance.interceptors.request.use(
    (config) => {
        const secureId = process.env.REACT_APP_SECURE_ID;
        const password = process.env.REACT_APP_PASSWORD;

        if (secureId && password) {
            config.headers['secureId'] = secureId;
            config.headers['password'] = password;
        } else {
            console.error("Missing secureId or password in environment variables");
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

axiosInstance.interceptors.response.use(
    function (response) {
        return response
    },
    function (error) {
        if (error && error.response) {
            if (error.response.status === 401) {
                console.error(error)
                // window.location.href = process.env.PUBLIC_URL + '/logout'
            } else if (error.response.status === 403) {
                console.error(error)
                // window.location.href = process.env.PUBLIC_URL + '/error/403'
            } else if (error.response.status === 500) {
                console.error(error)
                // window.location.href = process.env.PUBLIC_URL + '/error/500'
            }
        }
        return Promise.reject(error)
    }
)

export default axiosInstance;