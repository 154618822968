import { configureStore } from '@reduxjs/toolkit'
import { activitiesSlice } from './slices/activities/activitiesSlice'
import { monitorSlice } from './slices/monitor/monitorSlice'
import { rightsSlice } from './slices/rights/rightsSlice'
import { indicatorDisplayFilterSlice } from './slices/indicatorDisplay/SearchAndFilter/filterAndSearchSlice'
import { indicatorSlice } from './slices/indicatorDisplay/indicator/indicatorSlice'
import { researchsSlice } from './slices/researchs/researchsSlice'
import { methodologiesSlice } from './slices/methodologies/methodologySlice'
import { evaluatorSlice } from './slices/evaluator/evaluatorSlice'
import { periodsSlice } from './slices/periods/periodsSlice'

export const store = configureStore({
  reducer: {
    activities: activitiesSlice.reducer,
    researchs: researchsSlice.reducer,
    methodologies: methodologiesSlice.reducer,
    monitor: monitorSlice.reducer,
    rights: rightsSlice.reducer,
    indicator_display_filter: indicatorDisplayFilterSlice.reducer,
    indicator: indicatorSlice.reducer,
    evaluator: evaluatorSlice.reducer,
    periods: periodsSlice.reducer
  }
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch