import { useEffect, useState } from 'react';
import { colors } from '../utils/colors';

type DataKey = { key: string; color: string };

const useDataKey = (data: any[]) => {
    const [dataKey, setDataKey] = useState<DataKey[] | null>(null);

    useEffect(() => {
        if (data?.length) {
            const keys = Object.keys(data[0]).slice(1);

            // Map keys to color and set dataKey
            const combinedData = keys.map((key, index) => ({ key, color: colors[index] }));
            setDataKey(combinedData);
        }
    }, [data]);

    return { dataKey };
};

export default useDataKey;