import facebook from '../assets/icons/facebook.svg'
import tiktok from '../assets/icons/tik-tok.svg'
import whatsApp from '../assets/icons/whatsapp.svg'
import instagram from '../assets/icons/instagram.svg'
import x from '../assets/icons/x.svg'
import flag from '../assets/icons/flag-arg.jpg'
import youtube from '../assets/icons/youtube.svg'
//--> VERSIÓN MOBILE <--//
import facebookDark from '../assets/icons/facebook-dark.svg'
import tiktokDark from '../assets/icons/tik-tok-dark.svg'
import whatsAppDark from '../assets/icons/whatsapp-dark.svg'
import instagramDark from '../assets/icons/instagram-dark.svg'
import xDark from '../assets/icons/x-dark.svg'
import youtubeDark from '../assets/icons/youtube-dark.png'
import linkedin from '../assets/icons/linkedin.svg'
import linkedinDark from '../assets/icons/linkedin-dark.svg'

interface ISocialNetworks {
    withFlag: boolean
    gap: number
    isSocialNetworkResponsive?: boolean
}

const SocialNetworks = ({ withFlag, gap, isSocialNetworkResponsive = false, /*colorIcon, breakLine */ }: ISocialNetworks) => {
    return (
        <div className={`flex items-center gap-${gap} justify-between`}>
            <a href='https://www.tiktok.com/@defensoriacaba' target="_blank" rel="noreferrer" className='h-full  p-0 flex justify-center'>
                <img src={isSocialNetworkResponsive ? tiktokDark : tiktok} alt="icon-social-network" className='max-w-[34px]' />
            </a>
            <a href='https://twitter.com/DefensoriaCABA' target="_blank" rel="noreferrer" className='h-full  p-0 flex justify-center'>
                <img src={isSocialNetworkResponsive ? xDark : x} alt="icon-social-network" className='max-w-[34px]' />
            </a>
            <a href='https://www.facebook.com/DefensoriaCABA' target="_blank" rel="noreferrer" className='h-full  p-0 flex justify-center'>
                <img src={isSocialNetworkResponsive ? facebookDark : facebook} alt="icon-social-network" className='max-w-[34px] ' />
            </a>
            <a href='https://www.instagram.com/defensoriacaba/' target="_blank" rel="noreferrer" className='h-full  p-0 flex justify-center'>
                <img src={isSocialNetworkResponsive ? instagramDark : instagram} alt="icon-social-network" className='max-w-[34px]' />
            </a>
            <a href='https://wa.me/+5491171288301/?text=Hola%2C%20quiero%20hacer%20una%20consulta' target="_blank" rel="noreferrer" className='h-full  p-0 flex justify-center'>
                <img src={isSocialNetworkResponsive ? whatsAppDark : whatsApp} alt="icon-social-network" className='max-w-[34px]' />
            </a>
            <a href='https://www.youtube.com/user/defpueblo' target="_blank" rel="noreferrer" className='h-full  p-0 flex justify-center'>
                <img src={isSocialNetworkResponsive ? youtubeDark : youtube} alt="icon-social-network" className='max-w-[34px]' />
            </a>
            <a href='https://www.linkedin.com/company/defensoria-del-pueblo-caba/' target="_blank" rel="noreferrer" className='h-full  p-0 flex justify-center'>
                <img src={isSocialNetworkResponsive ? linkedinDark : linkedin} alt="icon-social-network" className='max-w-[34px]' />
            </a>
            {withFlag ? <img src={flag} alt="bandera-argentina" /> : ""}
        </div>
    )
}

export default SocialNetworks