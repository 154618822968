import { useEffect, useState } from "react"
import TitleEvaluations from "../../../components/Evaluations/TitleEvaluations"
import { DimensionEvaluation, Subdimensiones } from "../../../interfaces/Evaluations"
import ResponsiveContainer from "../../../layouts/ResponsiveContainer"
import Tabs from "../../../components/Tabs/Tabs"
import EvaluationSubdimensionResAndProc from "./EvaluationSubdimensionResAndProc"
import { INDICATOR_TYPE_STRUCTURAL } from "../../../utils/const"
import { useAppDispatch } from "../../../redux/hooks"
import { evaluationSubDimension, selectedDimension } from "../../../redux/slices/evaluator/evaluatorSlice"
import EvaluationSubdimensionStructural from "./EvaluationSubdimensionStructural"
import { MdOutlineInfo } from "react-icons/md"
import Dialog from "../../../components/Dialog/Dialog"

interface IEvaluationDimension {
    evaluation_dimension: DimensionEvaluation[],
    evaluation_subDimension_RES_PROC: Subdimensiones[],
    selected_type_indicator: string | null
}

const EvaluationDimension = ({ evaluation_dimension, evaluation_subDimension_RES_PROC, selected_type_indicator }: IEvaluationDimension) => {
    const dispatch = useAppDispatch();

    const [selectedId, setSelectedId] = useState<number | null>(null);
    const [isModalVisible, setIsModalVisible] = useState(false)

    useEffect(() => {
        // Seleccionar la primera dimensión al cambiar el tipo de indicador
        if (evaluation_dimension.length > 0) {
            const firstTabId = evaluation_dimension[0].id;
            setSelectedId(firstTabId);
            dispatch(selectedDimension({ dimension: evaluation_dimension[0].nombre ?? '' }))
            dispatch(evaluationSubDimension({ dimensionId: firstTabId }));
        }
    }, [selected_type_indicator, evaluation_dimension, dispatch]);


    const handleTabClick = (id: number, name?: string) => {
        setSelectedId(id);
        dispatch(selectedDimension({ dimension: name ?? '' }))
        dispatch(evaluationSubDimension({ dimensionId: id }))
    };

    const closeModal = () => {
        setIsModalVisible(false)
    }

    return (
        <div className='flex justify-center items-center flex-col'>
            {selected_type_indicator && selected_type_indicator.length !== null ? (
                <ResponsiveContainer>
                    <TitleEvaluations
                        label='Dimensiones'
                        textStyle="text-hsmall mt-10 mb-4"
                        onClick={() => setIsModalVisible(!isModalVisible)}
                    />
                    <Tabs
                        tabs={evaluation_dimension}
                        selectedIndex={selectedId}
                        onTabClick={handleTabClick}
                    />
                    {selected_type_indicator === INDICATOR_TYPE_STRUCTURAL
                        ? <EvaluationSubdimensionStructural
                            evaluation_subDimension={evaluation_subDimension_RES_PROC}
                        />
                        : <EvaluationSubdimensionResAndProc
                            selected_type_indicator={selected_type_indicator}
                            evaluation_subDimension={evaluation_subDimension_RES_PROC}
                        />
                    }
                </ResponsiveContainer>
            ) : null}
            <Dialog
                isOpen={isModalVisible}
                toggle={closeModal}
            >
                <div className="flex justify-center mb-2">
                    <MdOutlineInfo size={32} color='#0E6681' />
                </div>
                <h2 className="text-center text-hsmall text-system-light-100 mb-4">Información</h2>
                <p className="text-left font-bold text-bmedium text-primary-40">Dimensiones:</p>
                <p className="text-left text-bmedium text-system-light-100">Identifican distintos aspectos del derecho y nos permiten comprender mejor su alcance. Las definimos siguiendo criterios del Comité de Derechos Económicos, Sociales y Culturales de Naciones Unidas.</p>
                <br />
                <p className="text-left font-bold text-bmedium text-primary-40">Subdimensiones:</p>
                <p className="text-left text-bmedium text-system-light-100">Desagregan el contenido de las dimensiones para facilitar la lectura y el análisis de la información. Posibilitan un primer agrupamiento de los indicadores que, a su vez, reflejan variables conformadas por datos oficiales. Para saber más sobre las dimensiones y subdimensiones podés acceder a nuestra Metodología de Evaluación.</p>
            </Dialog>
        </div>
    );
}

export default EvaluationDimension