import { EvaluatedRights } from '../../interfaces/Rights';
import Images from '../Images/Images';
import '../styles.css';
import Evaluations from '../Evaluations/Evaluations';

interface IAccessToRigths extends EvaluatedRights {
    periodSelected: string;
}

const AccessToRigths = ({ color, icono, icono_base64, titulo, evaluaciones, periodSelected }: IAccessToRigths) => {
    // Garantiza que evaluaciones[periodSelected] no sea undefined
    const periodEvaluations = evaluaciones[periodSelected] || {};

    return (
        <div className="relative rounded-xl h-fit w-full">
            <div className='absolute w-full h-full rounded-xl' style={{ backgroundColor: color }}>
                <div className="imagen-bg-card-rights rounded-xl"></div>
            </div>
            <div className='w-full p-4 relative z-10'>
                <div className='flex items-center gap-4 pb-4'>
                    <Images imagen={icono} imagen_base64={icono_base64} className="w-[4.25rem] h-[3.375rem] object-contain" />
                    <h2 className='text-hmedium xl:text-hlarge text-primary-100'>{titulo}</h2>
                </div>
                <Evaluations
                    arithmeticalEvaluation={
                        periodEvaluations.suma_aritmetica !== undefined && periodEvaluations.suma_aritmetica !== null
                            ? [periodEvaluations?.suma_aritmetica]
                            : []
                    }
                    showArithmeticalIndicators={true}
                    arithmeticalEvaluationLabel='Indicadores por estado'
                    trivalentEvaluation={
                        periodEvaluations.evaluacion_trivalente !== undefined && periodEvaluations.evaluacion_trivalente !== null
                            ? periodEvaluations?.evaluacion_trivalente
                            : null
                    }
                    trivalentStateStyle='text-llarge'
                    evaluationStyle='text-llarge'
                    backgroundColorCard='#FFFFFF'
                    showTitleEvaluation={false}
                />
            </div>
        </div>
    );
};

export default AccessToRigths;
