const translateStatusColor = (status: number | null) => {
    if (status === null) return '#919091'; // Sin datos: gris
    if (status === -1 || status < -1) return '#FF6060'; // Retroceso: rojo
    if (status === 0) return '#EDBC3E'; // Sin cambios: amarillo
    if (status === 1 || status > 1) return '#03D68A'; // Mejora: verde
    return '';
};

const EvaluationStates = ({
    status,
    size = 4,
}: {
    status: number | null;
    size?: number;
}) => {
    const statusColorClass = translateStatusColor(status);

    return (
        <div className="rounded-full" style={{ width: `${size}px`, height: `${size}px`, backgroundColor: `${statusColorClass}` }}>
        </div>
    );
};

export default EvaluationStates;