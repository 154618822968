import React from 'react';
import { IconType } from 'react-icons';

interface ButtonProps {
    onClick?: () => void;
    type?: 'button' | 'submit' | 'reset';
    disabled?: boolean;
    label: string;
    icon?: IconType;
    as?: 'button' | 'link';
    href?: string;
    className?: string;
    showIconRight?: boolean;
    showIconLeft?: boolean;
    iconSize?: number
}

const Button: React.FC<ButtonProps> = ({
    onClick,
    type = 'button',
    disabled = false,
    label,
    icon: Icon,
    as = 'button',
    href,
    className,
    showIconLeft,
    showIconRight = true,
    iconSize = 16
}) => {
    const buttonClasses = `h-[2.5rem] w-max flex items-center ${label && label.length > 0 ? 'gap-2' : ''} rounded-full px-6 hover:brightness-[0.90] hover:shadow hover:shadow-gray-0/30 ${className}`;

    const buttonContent = (
        <>
            {showIconLeft && Icon && <Icon size={iconSize} />}
            <span className='text-llarge text-secondary-large'>{label}</span>
            {showIconRight && Icon && <Icon size={iconSize} />}
        </>
    );

    if (as === 'link') {
        return (
            <button
                // href={href}
                onClick={onClick}
                className={buttonClasses}
                style={{ textDecoration: 'none', ...(disabled && { opacity: 0.5, cursor: 'not-allowed' }) }}
                disabled={disabled}
            >
                {buttonContent}
            </button>
        );
    }

    return (
        <button
            onClick={onClick}
            type={type}
            disabled={disabled}
            className={`${buttonClasses} ${disabled ? 'opacity-50 cursor-not-allowed' : ''}`}
        >
            {buttonContent}
        </button>
    );
};

export default Button;