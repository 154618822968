import { createSlice } from '@reduxjs/toolkit'
import { IMediaAndContent } from '../../../interfaces/MediaAndContent'
import { getDetailAndRelatedMethodologies } from './methodologyThunk'
import { Pagination } from '../../../interfaces/Pagination'
import { ICategories } from '../../../interfaces/Rights';
import { CustomSelectsOption } from '../../../components/Inputs/Selects/CustomSelect';

interface methodologiesState {
    methodologies: IMediaAndContent[] | null;
    majormethodologies: IMediaAndContent[] | null;
    relatedmethodologies: IMediaAndContent[] | null;
    loading: "idle" | "pending" | "succeeded" | "failed";
    methodologiesDetail: IMediaAndContent | null | undefined
    pagination: Pagination;
    categories: ICategories[] | null;
    categoriesOptions: CustomSelectsOption[] | null
}

const initialState: methodologiesState = {
    loading: 'idle',
    methodologies: null,
    majormethodologies: null,
    relatedmethodologies: null,
    pagination: { totalItems: 0, currentPage: 1, pageSize: 5, totalPages: 0 },
    methodologiesDetail: null,
    categories: null,
    categoriesOptions: null
}

export const methodologiesSlice = createSlice({
    name: 'research',
    initialState,
    reducers: {
        clearmethodologies: (state) => {
            state.methodologies = null;
            state.majormethodologies = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getDetailAndRelatedMethodologies.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getDetailAndRelatedMethodologies.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.relatedmethodologies = action.payload.related;
            state.methodologiesDetail = action.payload.detail
        });
        builder.addCase(getDetailAndRelatedMethodologies.rejected, (state) => {
            state.loading = "failed";
        });
    },
})

export const { clearmethodologies } = methodologiesSlice.actions;

export default methodologiesSlice.reducer;