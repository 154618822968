import { IconType } from 'react-icons';

type TPopoverContent = {
    title: string,
    message: string,
    icon: IconType;
    colorIcon?: string | undefined
}

const PopoverContent = ({ title, message, icon: Icon, colorIcon }: TPopoverContent) => {
    return (
        <>
            <div className="flex justify-center mb-2">
                <Icon size={32} color={colorIcon} />
            </div>
            <h2 className="text-center text-hsmall text-system-light-100 mb-4">{title}</h2>
            <p className="text-center text-bmedium text-system-light-100">{message}</p>
        </>
    )
}

export default PopoverContent
