import Titles from '../../../components/Titles/Titles'
import ResponsiveContainer from '../../../layouts/ResponsiveContainer'
import EvaluationTypeIndicator from '../../../components/Cards/EvaluationTypeIndicator';
import { IndicatorTypeEvaluation } from '../../../interfaces/Evaluations';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { evaluationDimension, selectedTypeIndicator, clearDimensionEvaluations } from '../../../redux/slices/evaluator/evaluatorSlice';
import { useNavigate } from 'react-router-dom';
import { MdOutlineInfo } from 'react-icons/md';
import { useState } from 'react';
import Dialog from '../../../components/Dialog/Dialog';

const EvaluationTypeOfIndicator = ({ evaluation_indicator_type }: { evaluation_indicator_type: IndicatorTypeEvaluation[] }) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const [isModalVisible, setIsModalVisible] = useState(false)

    const selectedTypeId = useAppSelector((state) => state.evaluator.selected_type_indicator);

    const handleClick = (typeOfIndicatorId: number, typeOfIndicatorName: string) => {
        const params = new URLSearchParams(window.location.search);
        params.set('typeIndicator', typeOfIndicatorName)
        navigate({ search: params.toString() });

        dispatch(clearDimensionEvaluations());
        dispatch(selectedTypeIndicator({ typeOfIndicator: typeOfIndicatorName }));
        //Para obtener la evaluaciones de las dimensiones necesita saber cual es el tipo de indicador que las contiene
        dispatch(evaluationDimension({ typeOfIndicator: typeOfIndicatorId }));
    };

    return (
        <div className='flex justify-center items-center flex-col'>
            <ResponsiveContainer className='mt-12 flex flex-col items-center'>
                <>
                    <div className='bg-title-pattern bg-contain w-[9.375rem] h-[4.313rem]'>
                        <div className='h-full flex items-center gap-2 sm:w-max'>
                            <h1 className='text-hmedium md:text-hlarge text-primary-10 pl-1 w-full sm:w-max'>Tipos de indicadores</h1>
                            <button onClick={() => setIsModalVisible(!isModalVisible)}>
                                <MdOutlineInfo size={16} />
                            </button>
                        </div>
                    </div>
                </>
                <div className='flex w-full w-full justify-between mt-10 gap-4'>
                    {evaluation_indicator_type && evaluation_indicator_type.length > 0 && evaluation_indicator_type.map((element) => (
                        <EvaluationTypeIndicator
                            label={element.nombre}
                            status={element.trivalente_tipo_indicador}
                            size={12}
                            key={element.id}
                            isSelected={selectedTypeId === element.nombre}
                            handleClick={() => handleClick(element.id, element.nombre)}
                        />
                    ))}
                </div>
            </ResponsiveContainer>
            <Dialog
                isOpen={isModalVisible}
                toggle={() => setIsModalVisible(false)}
            >
                <div className="flex justify-center mb-2">
                    <MdOutlineInfo size={32} color='#0E6681' />
                </div>
                <h2 className="text-center text-hsmall text-system-light-100 mb-4">Información</h2>
                <p className="text-left font-bold text-bmedium text-primary-40">Tipos de indicador:</p>
                <p className="text-left text-bmedium text-system-light-100">Los distintos tipos de indicadores cumplen funciones específicas para el seguimiento y evaluación de los derechos. Los indicadores estructurales relevan información sobre las instituciones públicas y los marcos normativos destinados a su protección. Los indicadores de proceso examinan los recursos presupuestarios afectados a su realización. Por último, los indicadores de resultados brindan indicios - en su mayoría estadísticos - del impacto de las políticas públicas sobre distintos aspectos que hacen a su efectividad. Explorá los detalles en nuestra Metodología de Evaluación.</p>
            </Dialog>
        </div>
    )
}

export default EvaluationTypeOfIndicator