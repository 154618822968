import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
const { REACT_APP_API_PATH } = process.env;

export const getPeriods = createAsyncThunk(
    "monitor/getPeriods",
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/periodos/activos`)
            const data = response.data
            return {
                periods: data,
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);