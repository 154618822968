import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { ICategories } from "../../../interfaces/Rights";
import { Pagination } from "../../../interfaces/Pagination";
import { IMediaAndContent } from "../../../interfaces/MediaAndContent";
const { REACT_APP_API_PATH } = process.env;

interface GetActivitiesParams {
    page: number;
    search?: string;
    categoria?: string;
    fechaFrom?: string;
    fechaTo?: string;
}

//Intefaces de los gets

interface GetCategoriesResponse {
    categories: ICategories[];
}

interface GetActivitiesResponse {
    activities: [];
    pagination: Pagination;
}

interface GetDetailAndRelatedActivitiesResponse {
    detail: IMediaAndContent;
    related: IMediaAndContent[];
}

interface ErrorResponse {
    message: string;
    // otros campos relevantes del error
}

export const getActivities = createAsyncThunk<GetActivitiesResponse, GetActivitiesParams, { rejectValue: ErrorResponse }>(
    "activities/getActivities",
    async ({ page, search, categoria, fechaFrom, fechaTo }: GetActivitiesParams) => {
        try {
            let url = `${REACT_APP_API_PATH}/monitor/activities/actividades_destacadas?page=${page}`;

            if (search) url += `&titulo=${search}`
            if (categoria) url += `&categoria=${categoria}`
            if (fechaFrom) url += `&fechaFrom=${fechaFrom}`
            if (fechaTo) url += `&fechaTo=${fechaTo}`

            const response = await axiosInstance.get(url);
            const data = response.data;

            return {
                activities: data.data,
                pagination: data.pagination
            }

        } catch (error: any) {
            return {
                activities: [],
                pagination: { totalItems: 0, currentPage: 0, totalPages: 0 }
            }
        }
    }
);

export const getDetailAndRelatedActivities = createAsyncThunk<GetDetailAndRelatedActivitiesResponse, string, { rejectValue: ErrorResponse }>(
    "activities/getRelatedActivities",
    async (id: string, thunkAPI) => {
        try {
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/monitor/activities/actividades_relacionadas?id=${id}`)
            const data = response.data
            return {
                detail: data.data.activity,
                related: data.data.related
            }
        } catch (error: any) {
            return {
                detail: [],
                related: null,
            }
        }
    }
);

export const getCategories = createAsyncThunk<GetCategoriesResponse, void, { rejectValue: ErrorResponse }>(
    "activities/categories",
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/categorias/activas`)
            const data = response.data
            return {
                categories: data.data,
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);