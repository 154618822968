import { IArithmeticalEvaluation } from '../../interfaces/Rights';
import StackedBarChartStates from '../Charts/StackedBarChartStates';

interface IIndicators {
    data: IArithmeticalEvaluation[],
    showIndicators: boolean,
    label: string,
    backgroundColor: string
}

const ArithmeticalEvaluation = ({ data, label, showIndicators, backgroundColor }: IIndicators) => {

    return (
        <div className='rounded-xl w-full h-full' style={{ backgroundColor: backgroundColor }}>
            <div className='p-4 w-full'>
                {label.length ? <h6 className='text-llarge text-primary-10 text-center'>{label}</h6> : <></>}
                <StackedBarChartStates data={data} showIndicators={showIndicators} />
            </div>
        </div>
    )
}

export default ArithmeticalEvaluation