import '../styles.css'

const NotDataMap = () => {
    return (
        <div className='container-not-data-map'>
            <div className='bg-system-light-10'>
                <p className='text-tmedium text-system-light-40 p-4'>El mapa no tiene datos para el periodo seleccionado.</p>
            </div>
        </div>
    )
}

export default NotDataMap