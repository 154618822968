import React, { useState } from 'react'
import logo from '../assets/icons/logo-completo-defensoria.png'
import NavBar from './NavBar'
import SocialNetworks from './SocialNetworks'
import ResponsiveContainer from './ResponsiveContainer'
import { MdDehaze } from "react-icons/md";
import flag from '../assets/icons/flag-arg.jpg'
import './styles.css'

const Header = () => {

  const [isMenuVisible, setIsMenuVisible] = useState(false);

  const toggleMenu = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  return (
    <nav className='w-full'>
      <div className='h-[6.25rem] py-6 flex justify-center header-desing'>
        <ResponsiveContainer className='flex justify-between items-center'>
          <img src={logo} alt='Logo defensoria del pueblo' className='h-[2rem] w-[9.35rem] md:h-fit md:w-fit' />
          <button onClick={toggleMenu} className='md:hidden'>
            <MdDehaze size={24} color={'white'} />
          </button>
        </ResponsiveContainer>
      </div>
      {/* Navbar in screen md and > */}
      <div className='hidden md:flex md:justify-center'>
        <ResponsiveContainer>
          <NavBar />
        </ResponsiveContainer>
      </div>
      {/* Navbar in mobile */}
      {
        isMenuVisible && (
          <div className='w-[338px] p-3 absolute bg-primary-100 right-0 z-[3500] rounded-b-lg shadow-xl shadow-gray-0/30'>
            <NavBar />
          </div>
        )
      }
    </nav>
  )
}

export default Header