const FooterIndicator = ({ fuente }: { fuente: string }) => {
    return (
        <div className='bg-system-light-10 '>
            <div className='p-3 text-system-light-40 text-bmedium'>
                <p>Fuente: {fuente}</p>
            </div>
        </div>
    )
}

export default FooterIndicator