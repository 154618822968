import { useEffect, useRef } from 'react';
import { MdOutlineClose } from 'react-icons/md'

type TPopoverLayout = {
    onClose: () => void;
    children: React.ReactNode
    position?: { top: string, left: string };
}

const PopoverLayout = ({ onClose, children, position }: TPopoverLayout) => {
    return (
        <div className={`absolute bg-system-light-60 shadow-lg rounded-xl z-50 w-[386px]`} style={position}>
            <div className='px-6 py-4'>
                <button onClick={onClose} className="flex w-full justify-end">
                    <MdOutlineClose size={24} />
                </button>
                {children}
            </div>
        </div>
    )
}

interface IPopover {
    isOpen: boolean;
    toggle: () => void;
    children: React.ReactNode
    position?: { top: string, left: string };
}

const Popover = ({ isOpen, toggle, children, position }: IPopover) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                toggle();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    if (!isOpen) return null

    return (
        <div ref={dropdownRef}>
            <PopoverLayout onClose={toggle} position={position} >
                {children}
            </PopoverLayout>
        </div>

    )
}

export default Popover