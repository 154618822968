import { useEffect, useRef, useState } from "react";
import LawList from "./LawList";
import TimelineControls from "./TimelineControl";
import TimelinePoint from "./TimelinePoint";
import { Evaluaciones } from "../../interfaces/Evaluations";

const Timeline = ({ laws, period }: { laws: Evaluaciones; period: string }) => {
    const timelineRef = useRef<HTMLDivElement>(null);
    const lineRef = useRef<HTMLDivElement>(null);
    const [selectedYear, setSelectedYear] = useState<string | null>(period);
    const [showAll, setShowAll] = useState(false);
    const sortedYears = Object.keys(laws).sort((a, b) => parseInt(b) - parseInt(a));

    useEffect(() => {
        setSelectedYear(period);
    }, [period]);

    useEffect(() => {
        const adjustLineWidth = () => {
            if (timelineRef.current && lineRef.current) {
                lineRef.current.style.width = `${timelineRef.current.scrollWidth}px`;
            }
        };

        adjustLineWidth();
        window.addEventListener('resize', adjustLineWidth);
        return () => window.removeEventListener('resize', adjustLineWidth);
    }, [laws]);

    const handleMoveTimeline = (direction: 'prev' | 'next') => {
        if (!timelineRef.current || !selectedYear) return;

        const container = timelineRef.current;
        const currentIndex = sortedYears.indexOf(selectedYear);
        const itemWidth = container.offsetWidth / sortedYears.length;
        const newIndex = direction === 'prev' ? currentIndex - 1 : currentIndex + 1;

        if (newIndex >= 0 && newIndex < sortedYears.length) {
            container.scrollBy({ left: direction === 'prev' ? -itemWidth : itemWidth, behavior: 'smooth' });
            setSelectedYear(sortedYears[newIndex]);
        }
    };

    const toggleShowAll = () => setShowAll(!showAll);

    return (
        <div className="rounded-lg shadow-lg border border-system-light-60">
            <div className="p-8">
                {/* Timeline Points */}
                <div className="timeline" ref={timelineRef}>
                    <div className="middle-line" ref={lineRef}></div>
                    {sortedYears.map((year) => (
                        <TimelinePoint
                            key={year}
                            year={year}
                            isActive={selectedYear === year}
                            onClick={() => setSelectedYear(year)}
                        />
                    ))}
                </div>

                {/* Timeline Controls */}
                <TimelineControls
                    onPrev={() => handleMoveTimeline('prev')}
                    onNext={() => handleMoveTimeline('next')}
                    disablePrev={selectedYear === sortedYears[0]}
                    disableNext={selectedYear === sortedYears[sortedYears.length - 1]}
                />

                {/* Law List */}
                {selectedYear && (
                    <LawList
                        laws={laws[selectedYear] || []}
                        showAll={showAll}
                        toggleShowAll={toggleShowAll}
                    />
                )}
            </div>
        </div>
    );
};

export default Timeline;