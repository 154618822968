import { BaseMap } from "../../interfaces/Indicator";
import { MapContainer, TileLayer, GeoJSON, useMap } from "react-leaflet";
import { ResponsiveContainer } from "recharts";
import L from "leaflet";
import seg from "../../utils/seg.json";
import { useEffect, useState } from "react";
import './styles.css'
import NotDataMap from "../Cards/NotDataMap";

// Función para calcular intervalos y asignar colores
const calculateColor = (value: number, intervals: number[], colors: string[]) => {
    if (value < intervals[0]) {
        return colors[0];
    }

    for (let i = 0; i < intervals.length - 1; i++) {
        if (value >= intervals[i] && value < intervals[i + 1]) {
            return colors[i];
        }
    }

    return colors[colors.length - 1];
};

const Legend = ({ intervals, colors }: { intervals: number[], colors: string[] }) => {
    const labels: string[] = [];

    intervals.forEach((interval, index) => {
        const nextInterval = intervals[index + 1];
        const label =
            nextInterval !== undefined
                ? `${interval.toFixed(0)} - ${(nextInterval - 1).toFixed(0)}`
                : `${interval.toFixed(0)} o +`;
        labels.push(
            `<div style="display: flex; align-items: center; margin-bottom: 5px;">
                <div style="background:${colors[index]}; width: 20px; height: 20px; margin-right: 5px;"></div>
                ${label}
            </div>`
        );
    });

    return (
        <div className="legend text-llarge" style={{ position: "absolute", top: "20px", right: "20px", background: "white", padding: "10px", borderRadius: "5px", zIndex: 1000 }}>
            <p className="mb-2">Referencias</p>
            <div>{labels.map((label, index) => <div key={index} dangerouslySetInnerHTML={{ __html: label }} />)}</div>
        </div>
    );
};

const MarkersOnMap = () => {
    const map = useMap();

    const onEachFeature = (feature: any, layer: any) => {
        if (feature.properties && feature.properties.COMUNAS) {
            const comunaNumber = feature.properties.COMUNAS;
            const center = (layer as L.GeoJSON).getBounds().getCenter();

            L.marker(center, {
                icon: L.divIcon({
                    className: 'comuna-label',
                    html: `<div style="background: none; padding: 2px 5px; font-size: 24px; font-weight: 600; color: #0E6681;">${comunaNumber}</div>`,
                }),
            }).addTo(map);
        }
        layer.on({
            mouseover: () => map.getContainer().style.cursor = "default", // Siempre default
            mouseout: () => map.getContainer().style.cursor = "default",
        });
    };

    return <GeoJSON data={seg as any} onEachFeature={onEachFeature} />;
};

const HeatMap = ({ data, mapRef, errorsNoData }: { data: BaseMap[], mapRef: React.RefObject<HTMLDivElement>, errorsNoData: boolean }) => {
    // Calcular los intervalos
    const [intervals, setIntervals] = useState<number[]>([]);
    const [colors, setColors] = useState<string[]>([
        "#FFEDA0", "#FED976", "#FEB24C", "#FD8D3C", "#FC4E2A", "#E31A1C",
    ]);

    useEffect(() => {
        const values = data.map((d) => parseFloat(d.Valor!));
        const min = Math.min(...values);
        const max = Math.max(...values);

        const step = (max - min) / 5; // Divide el rango en 5 intervalos
        const intervals = [
            min,
            min + step,
            min + step * 2,
            min + step * 3,
            min + step * 4,
            max,
        ];
        setIntervals(intervals);
    }, [data]);

    const styleFeature = (feature: any) => {
        const comunaID = feature.properties.COMUNAS;
        const comunaData = data.find((item) => item.Comuna === comunaID.toString());

        if (!comunaData || !comunaData.Valor) {
            return {
                className: 'comuna-label',

                fillColor: "#FFEDA0", // Cambia dinámicamente si es necesario
                weight: 2,
                opacity: 1,
                color: "white",
                dashArray: "3",
                fillOpacity: 0.7,
                interactive: false, // Desactiva eventos interactivos en el pane
            };
        }

        const value = parseFloat(comunaData.Valor);
        const color = calculateColor(value, intervals, colors);

        return {
            className: 'comuna-label',
            fillColor: color,
            weight: 2,
            opacity: 1,
            color: "white",
            dashArray: "3",
            fillOpacity: 0.7,
            interactive: false, // Desactiva interacción en paths
        };
    };

    return (
        <ResponsiveContainer>
            <div style={{ position: "relative", height: "600px", marginBottom: "24px" }}>
                <div ref={mapRef} id="my-node" style={{ width: "100%", height: "100%", position: 'relative' }}>
                    <MapContainer
                        style={{ width: "100%", height: "100%" }}
                        center={[-34.619948411233096, -58.44110846308608]}
                        zoom={11.5}
                        scrollWheelZoom={false}
                        preferCanvas={true}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                            url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        <MarkersOnMap />
                        <GeoJSON data={seg as any} style={styleFeature} />
                    </MapContainer>
                    {/* Leyenda */}
                    <Legend intervals={intervals} colors={colors} />
                    {errorsNoData ? <NotDataMap /> : null}
                </div>
            </div>
        </ResponsiveContainer>
    );
};

export default HeatMap;