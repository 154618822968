import React from 'react'
import VideoAbout from '../../views/Home/components/VideoAbout'
import './styles.css'
import ResponsiveContainer from '../../layouts/ResponsiveContainer'

interface Media {
    type: 'image' | 'video' | ''
    src: string
    url: string,
    alt: string,
    className: string
}

interface IHero {
    breadcrumbs: React.ReactNode;
    title: string;
    paragraph: string;
    media: Media;
    withShape: boolean;
    layout: 'column' | 'row';
    contentContainerClassName?: string;
    backgroundClassName?: React.CSSProperties;
    imageContainerClassName?: React.CSSProperties; // Tipado para estilos en línea
}

const Hero = ({
    breadcrumbs,
    title,
    paragraph,
    media,
    withShape,
    layout,
    contentContainerClassName,
    backgroundClassName = { backgroundColor: '#E4FFF9' },
    imageContainerClassName
}: IHero) => {
    return (
        <div className={`${withShape ? '' : 'h-[33.625rem] sm:h-[25.813rem] md:h-[21.25rem]'} relative`}>
            <div className={`${withShape ? 'hero shape' : 'hero without-shape'}`} style={backgroundClassName as React.CSSProperties}>
                {/* Aplicando imageContainerClassName como estilos en línea */}
                <div
                    className={withShape ? 'img-hero shape' : 'img-hero without-shape'}
                    style={imageContainerClassName as React.CSSProperties}
                ></div>
            </div>
            <div className="flex justify-center">
                <ResponsiveContainer className={`${withShape ? 'pt-14 xl:pt-20 ' : 'pt-14'} flex ${contentContainerClassName} items-center gap-8 justify-between`}>
                    <div className={`${withShape && layout === 'row' ? 'xl:w-[34.5rem] lg:max-xl:w-[55rem]' : 'w-full'} z-10`}>
                        {breadcrumbs}
                        <h1 className="text-dsmall md:text-dmedium text-primary-10 py-6">{title}</h1>
                        <p className="text-blarge text-primary-10 whitespace-pre-line">{paragraph}</p>
                    </div>
                    {/* Renderizar medios según el tipo */}
                    {media && media.type === 'video' && (
                        <VideoAbout url={media.url} />
                    )}
                    {media && media.type === 'image' && (
                        <div className={`relative z-20 ${media.className}`}>
                            <img src={media.src} alt={media.alt} className={media.className} />
                        </div>
                    )}
                </ResponsiveContainer>
            </div>
        </div>
    )
}

export default Hero
