import { IMediaAndContent } from '../../interfaces/MediaAndContent';
import DateWithIcon from '../Dates/DateWithIcon';
import ChipsMapper from '../Mappers/ChipsMapper';
import Images from '../Images/Images';

const MediaAndContentMini = ({ imagen, imagen_base64, titulo, descripcion, fecha, categorias }: IMediaAndContent) => {

  return (
    <div className='w-[18.625rem] sm:w-[22.5rem] rounded-[12px] bg-system-light-10 cursor-pointer hover:shadow-md hover:shadow-gray-0/30'>
      <Images imagen={imagen} imagen_base64={imagen_base64} className='object-cover w-[22.5rem] h-[9.125rem] rounded-t-lg border border-system-light-20' />
      <div className='p-4 flex flex-col gap-2 bg-system-light-10 rounded-b-lg h-[16rem] w-[18.625rem] sm:w-[22.5rem] border border-system-light-20'>
        <h4 className='text-tlarge text-sistem-light-100 line-clamp-3'>{titulo}</h4>
        <ChipsMapper categorias={categorias} />
        <p className='text-bmedium text-sistem-light-100 line-clamp-3'>{descripcion}</p>
        <DateWithIcon date={fecha} />
      </div>
    </div>
  )
}

export default MediaAndContentMini