import { MdArrowBack, MdArrowForward } from 'react-icons/md';

const TimelineControls = ({
    onPrev,
    onNext,
    disablePrev,
    disableNext,
}: {
    onPrev: () => void;
    onNext: () => void;
    disablePrev: boolean;
    disableNext: boolean;
}) => (
    <div className="flex justify-end mb-8 gap-6">
        <button
            onClick={onPrev}
            className={`p-2 rounded-xl ${disablePrev
                ? 'opacity-50 cursor-not-allowed bg-system-light-60 text-primary-10'
                : 'bg-[#BBE9FF] text-primary-10'
                }`}
            disabled={disablePrev}
        >
            <MdArrowBack size={24} />
        </button>
        <button
            onClick={onNext}
            className={`p-2 rounded-xl ${disableNext
                ? 'opacity-50 cursor-not-allowed bg-system-light-60 text-primary-10'
                : 'bg-[#BBE9FF] text-primary-10'
                }`}
            disabled={disableNext}
        >
            <MdArrowForward size={24} />
        </button>
    </div>
);

export default TimelineControls