import React, { ReactElement, ReactNode, useState } from 'react'
import Button from '../../../components/Buttons/Button'
import Dialog from '../../../components/Dialog/Dialog';
import { MdInfo } from 'react-icons/md';
import { Parser } from "html-to-react";

const InstitucionalDesing = ({ institucional }: { institucional: string }) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Definir una interfaz personalizada que extiende los atributos de <a>
    interface AnchorProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> { }

    // Función para actualizar los enlaces
    function updateLinks(children: ReactNode): ReactNode {
        return React.Children.map(children, (child) => {
            if (React.isValidElement(child) && child.type === 'a') {
                // Clonar el elemento <a> con el atributo target="_blank"
                return React.cloneElement(child as ReactElement<AnchorProps>, {
                    target: '_blank',
                    rel: 'noopener noreferrer', // Seguridad adicional
                });
            }
            return child; // No modificar otros elementos
        });
    }

    return (
        <>
            <div className='rounded-lg bg-system-primary-20'>
                <div className='p-4 flex gap-6 justify-between items-center'>
                    <div className='flex flex-col gap-1'>
                        <p className='text-bmedium font-bold'>Diseño institucional</p>
                        <p className='text-bmedium'>Detalle de los organismos, jurisdicciones o entidades estatales que tienen a su cargo la elaboración y ejecución de las estrategias, políticas y programas dirigidos a garantizar el derecho.</p>
                    </div>
                    <Button
                        label='Ver'
                        className='text-primary-40'
                        onClick={() => setIsOpen(!isOpen)}
                    />
                </div>
            </div>

            <Dialog isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <div className='flex gap-3 flex-col'>
                    <div className='flex flex-col items-center'>
                        <MdInfo size={22} color='#0E6681' />
                        <p className='text-hsmall'>Diseño institucional</p>
                    </div>
                    {
                        institucional && <span className='text-bmedium text-system-light-40'>{updateLinks(Parser().parse(institucional).props.children)}</span>
                    }
                </div>
            </Dialog>
        </>
    )
}

export default InstitucionalDesing