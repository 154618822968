import React from 'react';
import { MdOutlineSearch } from 'react-icons/md';
import { searchOfIndicators } from '../../interfaces/Rights';
import Loading from '../Loading/Loading';
import '../styles.css';
import { MdOutlineCancel } from "react-icons/md";

interface IPredictiveSearchBar {
    placeholder: string;
    searchValue: string;
    searchResults: searchOfIndicators[];
    isLoading: boolean;
    isOpen: boolean;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    generalErrorMessage: string;
    searchErrorMessage: string;
    hasError: boolean;
    setHasError: React.Dispatch<React.SetStateAction<boolean>>;
    selectSearch: ({ indicator, indicatorID }: {
        indicator: string;
        indicatorID: number;
    }) => void;
    cleanValue: boolean
    onCleanValue: () => void
}

const PredictiveSearchBar = ({
    placeholder,
    searchValue,
    searchResults,
    isLoading,
    isOpen,
    onChange,
    generalErrorMessage,
    searchErrorMessage,
    hasError,
    setHasError,
    selectSearch,
    cleanValue,
    onCleanValue
}: IPredictiveSearchBar) => {

    return (
        <div className='w-full'>
            <SearchInput
                placeholder={placeholder}
                searchValue={searchValue}
                onChange={onChange}
                setHasError={setHasError}
                cleanValue={cleanValue}
                onCleanValue={onCleanValue}
            />
            {hasError && <ErrorMessage message={generalErrorMessage} />}
            <span className='block text-system-light-50 text-bmedium mt-1'>
                Debe ingresar al menos 3 letras del indicador a buscar.
            </span>
            {isOpen && <SearchResultsContainer
                isLoading={isLoading}
                searchResults={searchResults}
                searchErrorMessage={searchErrorMessage}
                selectSearch={selectSearch}
            />}
        </div>
    );
};

const SearchInput = ({
    placeholder,
    searchValue,
    onChange,
    setHasError,
    cleanValue,
    onCleanValue
}: {
    placeholder: string;
    searchValue: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    setHasError: React.Dispatch<React.SetStateAction<boolean>>;
    cleanValue: boolean
    onCleanValue: () => void
}) => (
    <label htmlFor="search" className='flex relative items-center'>
        <MdOutlineSearch size={24} id='icon' className='absolute ml-2' color='#40484C' />
        <input
            className='border border-system-light-50 text-blarge text-system-light-100 w-full h-full rounded-sm focus:border-1 focus:border-primary-40 pl-10 py-4 hover:border-1 hover:border-system-light-100 search-predictive'
            id='search'
            name='search'
            placeholder={placeholder}
            value={searchValue}
            aria-label={placeholder}
            onChange={onChange}
            onFocus={() => setHasError(false)}
            onBlur={() => setHasError(false)}
            autoComplete='off'
        />
        {
            cleanValue && <button className='absolute right-[10px] ' onClick={onCleanValue}><MdOutlineCancel size={24} color='#40484C' /></button>
        }
    </label>
);

const ErrorMessage = ({ message }: { message: string }) => (
    <span className='block text-indicator-red mt-1 text-bmedium'>{message}</span>
);

const SearchResultsContainer = ({
    isLoading,
    searchResults,
    searchErrorMessage,
    selectSearch
}: {
    isLoading: boolean;
    searchResults: searchOfIndicators[];
    searchErrorMessage: string;
    selectSearch: ({ indicator, indicatorID }: {
        indicator: string;
        indicatorID: number;
    }) => void
}) => (
    <div className='container-search absolute py-3 z-50 bg-primary-100 top-[145px] max-h-[260px] rounded-lg shadow-md overflow-y-auto'>
        {isLoading ? (
            <Loading size={30} color='#0E6681' />
        ) : (
            <>
                {searchResults && searchResults.length > 0 ? (
                    searchResults.map(({ titulo, subdimension, id }) => (
                        <SearchResult key={id} titulo={titulo} subdimension={subdimension} selectSearch={selectSearch} indicatorID={id} />
                    ))
                ) : (
                    <p className='text-center'>{searchErrorMessage}</p>
                )}
            </>
        )}
    </div>
);

const SearchResult = ({
    titulo,
    subdimension,
    selectSearch,
    indicatorID
}: {
    titulo: string;
    subdimension: string;
    indicatorID: number
    selectSearch: ({ indicator, indicatorID }: {
        indicator: string;
        indicatorID: number;
    }) => void;
}) => (
    <button onClick={() => selectSearch({ indicator: titulo, indicatorID: indicatorID })} className='py-3 px-4 w-full hover:bg-[#EDF1F5] cursor-pointer text-left'>
        <p className='text-system-light-100 text-bmedium'>{titulo}</p>
        <p className='text-primary-40 text-bsmall'>{subdimension}</p>
    </button>
);

export default PredictiveSearchBar;