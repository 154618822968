import React from 'react';

interface ResponsiveContainerProps {
    children: React.ReactNode;
    className?: string; // Propiedad opcional para clases adicionales
}

const ResponsiveContainer: React.FC<ResponsiveContainerProps> = ({ children, className }) => {
    const containerClasses = `w-[20.5rem] sm:w-[33.5rem] md:w-[53.563rem] lg:w-[55rem] xl:w-[70.5rem] ${className}`

    return (
        <div className={containerClasses}>
            {children}
        </div>
    );
};

export default ResponsiveContainer;