import React, { useRef, useState, useEffect } from "react";
import EvaluationStates from "../States/EvaluationStatesCircle";
import { DimensionEvaluation } from "../../interfaces/Evaluations";
import { MdChevronRight, MdChevronLeft } from "react-icons/md";
import './styles.css';

interface TabsProps {
    tabs: DimensionEvaluation[];
    selectedIndex: number | null;
    onTabClick: (index: number, name?: string) => void;
}

const Tabs = ({ tabs, selectedIndex, onTabClick }: TabsProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [hasOverflowLeft, setHasOverflowLeft] = useState(false);
    const [hasOverflowRight, setHasOverflowRight] = useState(false);

    // Función para comprobar el overflow
    const checkOverflow = () => {
        const container = containerRef.current;
        if (container) {
            setHasOverflowLeft(container.scrollLeft > 0);
            setHasOverflowRight(
                container.scrollWidth > container.offsetWidth &&
                container.scrollLeft < container.scrollWidth - container.offsetWidth
            );
        }
    };

    // Comprobar overflow al cargar el componente y en el redimensionamiento
    useEffect(() => {
        checkOverflow();
        const handleResize = () => checkOverflow();
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [tabs]);

    // Función para desplazar horizontalmente el contenedor
    const scroll = (direction: "left" | "right") => {
        const container = containerRef.current;
        if (container) {
            const scrollAmount = 225; // Cantidad de desplazamiento
            container.scrollBy({
                left: direction === "left" ? -scrollAmount : scrollAmount,
                behavior: "smooth",
            });
        }
    };

    return (
        <div className="relative flex items-center items-center">
            {/* Chevron Izquierdo */}
            {hasOverflowLeft && (
                <button
                    onClick={() => scroll("left")}
                    className="w-[32px] flex items-center justify-center"
                >
                    <MdChevronLeft size={24} color={'#001F29'}/>
                </button>
            )}
            <div
                ref={containerRef}
                className="flex gap-5 border-b border-system-primary-20 overflow-auto scrollbar-hide flex-grow"
                onScroll={checkOverflow}
            >
                {tabs.map((tab) => (
                    <button
                        className={`flex items-center gap-2 p-4 justify-center min-w-max ${selectedIndex === tab.id
                            ? "border-b-2 border-primary-40"
                            : "hover:bg-[#EDEDED]"
                            }`}
                        key={tab.id}
                        onClick={() => onTabClick(tab.id, tab.nombre)}
                    >
                        <EvaluationStates
                            status={tab.trivalente_dimension}
                            size={12}
                        />
                        <span
                            className={`text-llarge ${selectedIndex === tab.id
                                ? "text-system-light-100"
                                : "text-system-light-40"
                                }`}
                        >
                            {tab.nombre}
                        </span>
                    </button>
                ))}
            </div>

            {/* Chevron Derecho */}
            {hasOverflowRight && (
                <button
                    onClick={() => scroll("right")}
                    className="w-[32px] flex items-center justify-center"
                >
                    <MdChevronRight size={24} color={'#001F29'}/>
                </button>
            )}
        </div>
    );
};

export default Tabs;