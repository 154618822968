import { CartesianGrid, Legend, Line, LineChart, ReferenceLine, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { BarCharts, GraphicsDomain } from '../../interfaces/Indicator';
import useDataKey from '../../hooks/useDataKey';
import { getDomain } from '../../utils/utils';
import { forwardRef, useEffect, useState } from 'react';
import CustomToolTip from './CustomToolTip';
import { INDICATOR_TYPE_PROCESS } from '../../utils/const';

interface LineChartsProps {
    data: BarCharts[];
    typeOfIndicador: string;
    variable_analisis: string[];
    range?: GraphicsDomain | null;
}

const LineCharts = forwardRef<HTMLDivElement, LineChartsProps>(({ data, typeOfIndicador, variable_analisis, range }, ref) => {
    const [domain, setDomain] = useState<[number, number] | [string, string] | [number, string]>(['auto', 'auto']);

    const getSearchPredicate = () => {
        return (key: string) => {
            const lowerKey = key.toLowerCase();

            if (typeOfIndicador === INDICATOR_TYPE_PROCESS) {
                return variable_analisis.some(varAnalisis => lowerKey.includes(varAnalisis.toLowerCase()));
            }

            return variable_analisis.some(varAnalisis => lowerKey.includes(varAnalisis.toLowerCase()));
        };
    };

    useEffect(() => {
        if (range !== null && range!.minimo !== null && range!.maximo !== null) {
            setDomain([range!.minimo, range!.maximo]);
        }
        else {
            const [min, max] = getDomain(data, getSearchPredicate());
            setDomain([min, max]);
        }
    }, [data, typeOfIndicador, variable_analisis]);

    const { dataKey } = useDataKey(data);

    const filteredDataKey = dataKey?.filter((key) => {
        if (typeOfIndicador === INDICATOR_TYPE_PROCESS) {
            const matches = variable_analisis.some((variable) =>
                key.key.toLowerCase().includes(variable.toLowerCase())
            );
            return matches;
        }
        return true;
    });

    return (
        <ResponsiveContainer width="100%" height={406} ref={ref}>
            <LineChart data={data} margin={{ top: 52, right: 32, left: 32, bottom: 52 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" stroke="#555C62" />
                <YAxis domain={domain} ticks={range?.ticks ?? undefined} tickCount={range?.intervalo ?? undefined} stroke="#555C62" />
                <Tooltip content={<CustomToolTip />} />
                <Legend />
                <ReferenceLine y={0} stroke="red" strokeWidth="8 8" />

                {filteredDataKey?.map((key, index) => (
                    <Line
                        key={index}
                        name={key.key.split('-').map(entity => entity.split(':')[1]).join('/') ?? '-'}
                        type="monotone"
                        dataKey={key.key}
                        stroke={key.color}
                    />
                ))}
            </LineChart>
        </ResponsiveContainer>
    );
});

export default LineCharts;