import * as htmlToImage from 'html-to-image';

export const handleDownloadMap = (nodeRef: React.RefObject<HTMLDivElement>, name: string, format: 'png' | 'jpg') => {
    const node = nodeRef.current;
    if (node) {
        htmlToImage.toCanvas(node)
            .then(function (canvas) {
                const imgData = canvas.toDataURL(`image/${format}`);
                const link = document.createElement('a');
                link.href = imgData;
                link.download = `${name}.${format}`;
                link.click(); // Forzar la descarga de la imagen
            })
            .catch(function (error) {
                console.error('Error al generar la imagen del mapa:', error);
            });
    } else {
        console.error('No se encontró el nodo para capturar');
    }
};