import { Route, RouterProvider, createBrowserRouter, createRoutesFromElements, Navigate } from 'react-router-dom'
import Home from '../views/Home/index'
import Activities from '../views/Activities/index'
import Researchs from '../views/Researchs/index'
import ResearchsDetail from '../views/Researchs/Detail'
import MethodologyDetail from '../views/Methodology/Detail'
import About from '../views/About/index'
import RootLayout from '../layouts/RootLayout'
import ActivitiesDetail from '../views/Activities/Detail'
import ContactUs from '../views/ContactUs/Index'
import IndicatorDisplay from '../views/IndicatorDisplay/index'
import Evaluador from '../views/Evaluator/index'

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route
            element={<RootLayout />}
        >
            <Route path='/monitor'>
                <Route index element={<Home />} />
                <Route path='visualizador-de-indicadores' element={<IndicatorDisplay />} />
                <Route path='actividades'>
                    <Route index element={<Activities />} />
                    <Route path=':title' element={<ActivitiesDetail />} />
                </Route>
                <Route path='investigaciones-y-acciones'>
                    <Route index element={<Researchs />} />
                    <Route path=':title' element={<ResearchsDetail />} />
                </Route>
                <Route path='metodologia' element={<MethodologyDetail />} />
                <Route path='que-es' element={<About />} />
                <Route path='contactanos' element={<ContactUs />} />
                <Route path='evaluador' element={<Evaluador />} />
            </Route>
            <Route path='*' element={<Navigate to='/monitor' replace />} />
        </Route>
    )
)

const Root = () => {
    return (
        <RouterProvider router={router} />
    )
}

export default Root