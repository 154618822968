import { MdOutlineInfo } from "react-icons/md";

interface ITitleEvaluations {
    label: string,
    onClick?: (e?: any) => void,
    textStyle?: string
}

const TitleEvaluations = ({ label, onClick, textStyle = 'text-llarge' }: ITitleEvaluations) => {
    return (
        <div className={`${textStyle} text-primary-10 flex items-center gap-1`}>
            <span>{label}</span>
            <button onClick={onClick}>
                <MdOutlineInfo size={16} />
            </button>
        </div>
    )
}

export default TitleEvaluations