const TimelinePoint = ({ year, isActive, onClick }: { year: string, isActive: boolean, onClick: () => void }) => (
    <button
        className={`timeline-point box ${isActive ? 'active' : ''}`}
        onClick={onClick}
    >
        <span className={`mb-2 ${isActive ? 'text-tmedium text-primary-40' : 'text-bsmall text-[#001F29]'}`}>{year}</span>
        <div className={`relative ${isActive ? 'w-[36px] h-[36px] border rounded-full border-[#51BCAD] bg-primary-100' : 'w-[22px] h-[22px] border rounded-full border-[#C0C8CC] bg-primary-100'}`}>
            <div className={`absolute ${isActive ? 'w-[22px] h-[22px] rounded-full bg-[#51BCAD] top-[6px] right-[6px]' : 'w-[12px] h-[12px] rounded-full bg-[#C0C8CC] top-[4px] right-[4px]'}`}></div>
        </div>
    </button>
);

export default TimelinePoint