import { RefObject } from 'react';
import useDataKey from './useDataKey';
import { Graphics } from '../interfaces/Indicator';
import { INDICATOR_TYPE_PROCESS } from '../utils/const';

const useDownloadChart = (
    chartRef: RefObject<any>,
    graphics: Graphics,
    source: string,
    typeOfIndicator: string,
    filtersSelected: { [key: string]: string }
) => {
    const { dataKey } = useDataKey(graphics?.data_formated!);

    // Función para dividir el texto en líneas, si es necesario
    const wrapText = (
        ctx: CanvasRenderingContext2D,
        text: string,
        x: number,
        y: number,
        maxWidth: number,
        lineHeight: number
    ) => {
        const words = text.split(' ');
        let line = '';
        let lineY = y;

        for (let i = 0; i < words.length; i++) {
            const testLine = line + words[i] + ' ';
            const testWidth = ctx.measureText(testLine).width;
            if (testWidth > maxWidth && i > 0) {
                ctx.fillText(line, x, lineY);
                line = words[i] + ' ';
                lineY += lineHeight; // Se ajusta la altura de la línea
            } else {
                line = testLine;
            }
        }
        ctx.fillText(line, x, lineY);
    };


    const downloadChart = (format: 'png' | 'jpg' = 'png') => {
        if (chartRef.current) {
            const svgElement = chartRef.current.querySelector('svg');
            if (svgElement) {
                const svgData = new XMLSerializer().serializeToString(svgElement);
                const canvas = document.createElement('canvas');
                const svgSize = svgElement.getBoundingClientRect();

                // Ajusta el tamaño del canvas para incluir título y leyenda
                const titlesHeight = 50;
                const legendHeight = 30;
                const noteAndSource = 100;
                const canvasPadding = 20;
                const margin = 20;

                canvas.width = svgSize.width + canvasPadding + margin * 2;
                canvas.height = titlesHeight + svgSize.height + legendHeight + noteAndSource;

                const ctx = canvas.getContext('2d');
                const img = new Image();
                const svgBlob = new Blob([svgData], { type: 'image/svg+xml;charset=utf-8' });
                const url = URL.createObjectURL(svgBlob);

                img.onload = () => {
                    if (ctx) {
                        if (format === 'jpg') {
                            ctx.fillStyle = '#FFFFFF';
                            ctx.fillRect(0, 0, canvas.width, canvas.height);
                        }

                        // Dibuja el título en la parte superior del canvas
                        ctx.font = '18px Roboto';
                        ctx.fillStyle = 'rgb(14 102 129)';
                        ctx.textAlign = 'left';
                        ctx.fillText(graphics.titulo, margin, margin + 30);

                        // Dibujar los filtros seleccionados debajo del título
                        let contentHeight = margin + 50; // Inicializar la altura acumulada
                        if (filtersSelected && Object.keys(filtersSelected).length > 0) {
                            const filtersTextLines = Object.entries(filtersSelected).map(
                                ([key, value]) => `${key}: ${value}`
                            );

                            ctx.font = '14px Roboto';
                            ctx.fillStyle = '#000';

                            const filtersHeaderY = contentHeight;
                            ctx.fillText('Filtros seleccionados:', margin, filtersHeaderY);

                            let filtersY = filtersHeaderY + 20;
                            filtersTextLines.forEach((line) => {
                                ctx.fillStyle = '#666';
                                ctx.fillText(line, margin, filtersY);
                                filtersY += 20;
                            });

                            // Actualiza contentHeight sumando la altura de los filtros
                            contentHeight = filtersY + 10;
                        }

                        // Dibuja el gráfico SVG debajo de los filtros seleccionados
                        ctx.drawImage(img, margin, contentHeight); // Ajusta la imagen con el nuevo contentHeight
                        URL.revokeObjectURL(url);

                        // Filtrar y dibujar los items de la leyenda debajo del gráfico
                        const legendItems = typeOfIndicator === INDICATOR_TYPE_PROCESS
                            ? dataKey?.filter((item) => item.key.toLowerCase().includes('variación'))
                            : dataKey;

                        ctx.font = '14px Roboto';
                        let legendY = contentHeight + svgSize.height + 20;
                        const maxWidth = canvas.width - margin * 2 - 40;

                        legendItems?.forEach((item) => {
                            let currentLineWidth = 0;

                            legendItems.forEach((item) => {
                                const textWidth = ctx.measureText(item.key).width;
                                const legendItemWidth = 16 + 5 + textWidth + 25;

                                if (currentLineWidth + legendItemWidth > maxWidth) {
                                    currentLineWidth = 0;
                                    legendY += 25;
                                }

                                ctx.fillStyle = item.color;
                                ctx.fillRect(margin + currentLineWidth, legendY, 16, 16);
                                ctx.fillStyle = item.color;
                                ctx.fillText(item.key, margin + currentLineWidth + 25, legendY + 12);

                                currentLineWidth += legendItemWidth;
                            });
                        });

                        // Dibuja la nota y el source debajo del legend
                        const legendBottomY = legendY + legendHeight + 10;
                        const lineHeight = 18;

                        ctx.font = '14px Roboto';
                        ctx.fillStyle = '#000';

                        if (graphics?.nota_grafico?.length) {
                            wrapText(ctx, `Notas: ${graphics?.nota_grafico}`, margin, legendBottomY, maxWidth, lineHeight);
                        }

                        if (source?.length) {
                            const noteHeight = legendBottomY + 20;
                            wrapText(ctx, `Fuente: ${source}`, margin, noteHeight, maxWidth, lineHeight);
                        }
                    }

                    // Crear un enlace para descargar la imagen
                    const imgURL = canvas.toDataURL(`image/${format}`);
                    const downloadLink = document.createElement('a');
                    downloadLink.href = imgURL;
                    downloadLink.download = `${graphics.titulo}.${format}`;
                    downloadLink.click();
                };

                img.src = url;
            }
        }
    };

    return { downloadChart };
};

export default useDownloadChart;