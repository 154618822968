import { useEffect, useState, useMemo } from 'react';
import Dropdown, { CustomSelectsOption } from '../../../../components/Inputs/Selects/CustomSelect';
import { AllData, Filtro, Variable_indicator } from '../../../../interfaces/Indicator';
import { INDICATOR_TYPE_RESULTS } from '../../../../utils/const';
import { filterByCriteria } from '../../../../utils/utils';
import { useLocation } from 'react-router-dom';

type Variables = {
  name: string;
  values: CustomSelectsOption[];
}

interface FilterOfGraphsProps {
  variables_indicador: Variable_indicator[];
  typeOfIndicator: string;
  data: AllData[]
  setDataChart: (data: any[]) => void;
  variable_analisis: string[];
  cleanFilters: boolean;
  setCleanFilters: React.Dispatch<React.SetStateAction<boolean>>
  setFiltersSelected: React.Dispatch<React.SetStateAction<{
    [key: string]: string;
  }>>;
  isPercentageChart: null | 1 | 0;
  handleCleanFilters: () => void;
  filters: Filtro[];
  typeOfGraph: 1 | 2 | 3 | 4 | 5 | 6 | 7 | number;
  setErrorNoData: React.Dispatch<React.SetStateAction<boolean>>
}

const FilterOfGraphs = ({
  variables_indicador,
  typeOfIndicator,
  data,
  setDataChart,
  variable_analisis,
  cleanFilters,
  setCleanFilters,
  setFiltersSelected,
  isPercentageChart,
  handleCleanFilters,
  filters,
  typeOfGraph,
  setErrorNoData
}: FilterOfGraphsProps) => {
  const location = useLocation();

  const [variables, setVariables] = useState<Variables[]>([]);
  const [selectedVariables, setSelectedVariables] = useState<{ [key: string]: string }>({});
  const [initialFilters, setInitialFilters] = useState<{ [key: string]: string }>({}); // Guardar estado inicial

  useEffect(() => {
    if (cleanFilters) {
      setSelectedVariables(initialFilters);
      setCleanFilters(false);
      const filteredProducts = filterByCriteria(initialFilters, data, variable_analisis[0] || '');
      setDataChart(filteredProducts);
    }
  }, [cleanFilters, initialFilters]);

  const selectedVariable = (label: string, value?: string) => {
    setSelectedVariables((prevSelected) => ({
      ...prevSelected,
      [label]: value || '',
    }));

    const filtro = { ...selectedVariables, [label]: value || '' };

    if (!typeOfGraph) return

    if (typeOfGraph === 6 || typeOfGraph === 7) {
      const filtroAnio = filtro['Año'];
      if (filtroAnio) {
        const filteredDataMap = data.filter((item: any) => item.Anio === filtroAnio)
        if (filteredDataMap) {
          setDataChart(filteredDataMap);
          setErrorNoData(false)
        } else setErrorNoData(true)
      }
    } else {
      const filteredProducts = filterByCriteria(filtro, data, variable_analisis?.[0] || '');
      setDataChart(filteredProducts);
      setFiltersSelected(filtro);
    }
  };

  const transformedVariables = useMemo(() => {
    // Si el gráfico es de tipo 6 o 7 mapa o mapa de calor
    if (typeOfGraph === 6 || typeOfGraph === 7) {
      return variables_indicador?.map(variable => ({
        name: variable.titulo,
        values: variable.values
          .filter(value => value !== null)
          .map((value: string, index: number) => ({
            value: value,
            id: index + 1
          }))
      })) || [];
    }

    // Validar la existencia de variables_indicador y filters para otros tipos de gráficos
    if (variables_indicador && Array.isArray(variables_indicador) && filters) {
      const filtrosMap = filters.length > 0
        ? new Map(filters.map(filtro => [filtro.variable_id, filtro]))
        : null;

      const variablesTransformed = variables_indicador
        .filter(variable => {
          const perteneceAFiltros = filtrosMap ? filtrosMap.has(variable.id) : false;
          const perteneceAAnalisis = variable_analisis?.includes(variable.titulo);
          return (
            variable.values.some(value => value !== null) &&
            (filtrosMap ? perteneceAFiltros : perteneceAAnalisis)
          );
        })
        .map(variable => {
          const filtro = filtrosMap?.get(variable.id);
          const incluirTodos = filtro?.todos === 1 || variable_analisis?.includes(variable.titulo);

          return {
            name: variable.titulo,
            orden: filtro?.orden ?? Number.MAX_SAFE_INTEGER,
            values: [
              ...(incluirTodos
                ? [{ value: 'Todos', id: 0 }]
                : []),
              ...variable.values
                .filter(value => value !== null)
                .map((value: string, index: number) => ({
                  value: value,
                  id: index + 1
                }))
            ]
          };
        });

      return variablesTransformed.sort((a, b) => {
        if (a.orden !== b.orden) return a.orden - b.orden;
        if (variable_analisis?.includes(a.name)) return -1;
        if (variable_analisis?.includes(b.name)) return 1;
        return 0;
      });
    }

    // Retornar vacío si no se cumplen las condiciones
    return [];
  }, [typeOfGraph, variables_indicador, isPercentageChart, variable_analisis, filters]);

  useEffect(() => {
    setVariables(transformedVariables);
  }, [transformedVariables]);

  useEffect(() => {
    // Verificar si el tipo de gráfico es 6 o 7 y evitar la ejecución
    if (typeOfGraph === 6 || typeOfGraph === 7) return; // Salir del efecto si es tipo de gráfico 6 o 7

    if (variable_analisis && variable_analisis.length > 0 && variables_indicador.length > 0) {
      const initialSelected: { [key: string]: string } = {};

      variables.forEach((variable) => {
        if (variable_analisis.includes(variable.name)) {
          initialSelected[variable.name] = "Todos";
        } else {
          const firstValue = variable.values[0]?.value || "";
          if (firstValue) {
            initialSelected[variable.name] = firstValue;
          }
        }
      });

      setSelectedVariables(initialSelected);
      setInitialFilters(initialSelected); // Guardar el estado inicial

      const filteredProducts = filterByCriteria(initialSelected, data, variable_analisis[0] || '');
      setDataChart(filteredProducts);
      setFiltersSelected(initialSelected);
    }
  }, [
    isPercentageChart,
    variables,
    variable_analisis,
    variables_indicador,
    data,
    setDataChart,
    setFiltersSelected,
    typeOfGraph,
  ]);

  // Manejo específico para gráficos tipo 6 o 7
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const period = params.get('period') || '';

    if (typeOfGraph === 6 || typeOfGraph === 7 && period) {
      const filteredDataMap = data.filter((item: any) => item.Anio === period);

      if (filteredDataMap.length > 0) {
        setDataChart(filteredDataMap)
        setErrorNoData(false)
      } else setErrorNoData(true)
      setSelectedVariables({ Año: period });
    }
  }, [typeOfGraph, data, location, setDataChart]);

  // Función para verificar si el estado actual es igual al inicial
  const areFiltersModified = (): boolean => {
    return !Object.keys(initialFilters).every(
      key => initialFilters[key] === selectedVariables[key]
    );
  };

  if (typeOfIndicator !== INDICATOR_TYPE_RESULTS) return null

  return (
    <div className='flex wrap gap-3 items-end'>
      {variables.length > 0 ? (
        variables.map(variable => (
          <Dropdown
            key={variable.name}
            options={variable.values}
            onSelect={selectedVariable}
            selectedItem={selectedVariables[variable.name] || ''}
            defaultSelectedItem={selectedVariables[variable.name] || 'Seleccione'}
            label={variable.name}
            className='min-w-[175px]'
          />
        ))
      ) : null}

      {variables.length > 0 && areFiltersModified() && (
        <button
          onClick={handleCleanFilters}
          className='text-llarge text-primary-40 h-[20px] min-w-[7rem] rounded hover:bg-blue-600 transition-colors'
        >
          Limpiar Filtros
        </button>
      )}
    </div>
  );
}

export default FilterOfGraphs;