import noGraphics from '../../../../assets/img/no-graphics.png'

const NoGraphics = () => {
    return (
        <div className='h-[406px] w-full flex flex-col items-center justify-center'>
            <div className='flex flex-col items-center justify-center gap-4'>
                <p className='text-tlarge text-system-light-50 w-[400px] text-center'>¡Estamos trabajando sobre el gráfico, pronto tendrás novedades!</p>
                <img src={noGraphics} alt='No hay gráficos disponibles' />
            </div>
        </div>
    )
}

export default NoGraphics