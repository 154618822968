import React from 'react'
import NumberCharts from '../../../components/Cards/NumberCharts'
import ResponsiveContainer from '../../../layouts/ResponsiveContainer'

const goals = [
    { id: '1', number: 1, text: 'Relevar datos oficiales y optimizar su análisis' },
    { id: '2', number: 2, text: 'Evaluar el estado de los derechos humanos y visibilizar vulneraciones estructurales' },
    { id: '3', number: 3, text: 'Impulsar acciones que protejan esos derechos y promuevan su sostenibilidad' },
]

const GoalsAndObjectives = () => {
    return (
        <div className='flex justify-center bg-system-light-10 w-full py-20 mt-20'>
            <ResponsiveContainer className='flex flex-col justify-center'>
                <div className='flex flex-col items-center gap-14'>
                    <h2 className='text-hmedium md:text-dsmall text-primary-10' >Metas y objetivos</h2>
                    <div className='flex flex-col lg:flex-row items-center justify-between w-full'>
                        {
                            goals.map((goal) => (
                                <NumberCharts
                                    key={goal.id}
                                    number={goal.number}
                                    text={goal.text}
                                />
                            ))
                        }
                    </div>
                </div>
            </ResponsiveContainer>
        </div>
    )
}

export default GoalsAndObjectives