import React from 'react'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

interface INavigationHeader {
  title: string,
  goForward: () => void;
  goBack: () => void;
  disabledGoBack: boolean;
  disabledGoForward: boolean;
}

const NavigationHeader = ({ title, goBack, goForward, disabledGoBack, disabledGoForward }: INavigationHeader) => {
  return (
    <div className="flex justify-between gap-4 py-4 px-8  rounded-t-md bg-primary-10">
      <button disabled={disabledGoBack} onClick={goBack} className={`  flex items-center text-primary-100 ${disabledGoBack ? 'opacity-60 cursor-not-allowed' : ''}`}>
        <FiChevronLeft /> <span className='text-lmedium'>Gráfico anterior</span>
      </button>
      <div className="text-primary-100 text-tmedium text-center w-[65%]">{title}</div>
      <button disabled={disabledGoForward} onClick={goForward} className={` flex items-center text-primary-100 ${disabledGoForward ? 'opacity-60 cursor-not-allowed' : ''}`}>
        <span className='text-lmedium'>Siguiente gráfico</span><FiChevronRight />
      </button>
    </div>
  )
}

export default NavigationHeader