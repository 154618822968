import { createSlice } from '@reduxjs/toolkit'
import { IMediaAndContent } from '../../../interfaces/MediaAndContent'
import { getResearchs, getDetailAndRelatedresearchs, getCategories, getDetailAndRelatedresearchsByDerechoId } from './researchsThunk'
import { Pagination } from '../../../interfaces/Pagination'
import { ICategories } from '../../../interfaces/Rights';
import { CustomSelectsOption } from '../../../components/Inputs/Selects/CustomSelect';

interface researchsState {
    researchs: IMediaAndContent[] | null;
    majorresearchs: IMediaAndContent[] | null;
    relatedresearchs: IMediaAndContent[] | null;
    related_researchsByDerecho: IMediaAndContent[] | null;
    loading: "idle" | "pending" | "succeeded" | "failed";
    researchsDetail: IMediaAndContent | null | undefined
    pagination: Pagination;
    categories: ICategories[] | null;
    categoriesOptions: CustomSelectsOption[] | null
}

const initialState: researchsState = {
    loading: 'idle',
    researchs: null,
    majorresearchs: null,
    relatedresearchs: null,
    related_researchsByDerecho: null,
    pagination: { totalItems: 0, currentPage: 1, pageSize: 5, totalPages: 0 },
    researchsDetail: null,
    categories: null,
    categoriesOptions: null
}

export const researchsSlice = createSlice({
    name: 'research',
    initialState,
    reducers: {
        clearResearchs: (state) => {
            state.researchs = null;
            state.majorresearchs = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getResearchs.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getResearchs.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.researchs = action?.payload?.researchs;
            //this researchs are seen in the home
            state.majorresearchs = action?.payload?.researchs.slice(0, 3);
            state.pagination = action?.payload?.pagination
        });
        builder.addCase(getResearchs.rejected, (state) => {
            state.loading = "failed";
        });
        builder.addCase(getDetailAndRelatedresearchs.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getDetailAndRelatedresearchs.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.relatedresearchs = action.payload.related;
            state.researchsDetail = action.payload.detail
        });
        builder.addCase(getDetailAndRelatedresearchsByDerechoId.rejected, (state) => {
            state.loading = "failed";
        });
        builder.addCase(getDetailAndRelatedresearchsByDerechoId.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getDetailAndRelatedresearchsByDerechoId.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.related_researchsByDerecho = action.payload.related;
            state.researchsDetail = action.payload.detail
        });
        builder.addCase(getDetailAndRelatedresearchs.rejected, (state) => {
            state.loading = "failed";
        });
        builder.addCase(getCategories.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.categories = action.payload.categories
            //Options for dropdrown
            let categorieOption: CustomSelectsOption[] = action?.payload?.categories.map(({ titulo, id }) => ({ id: id, value: titulo }))
            state.categoriesOptions = [...categorieOption, { id: 'todas', value: 'Todas' }]
        });
        builder.addCase(getCategories.rejected, (state) => {
            state.loading = "failed";
        });
    },
})

export const { clearResearchs } = researchsSlice.actions;

export default researchsSlice.reducer;