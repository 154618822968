import { useForm } from 'react-hook-form'
import Button from '../../../components/Buttons/Button'
import { FormInput } from '../../../components/Inputs/Text/FormInput'
import mail from '../../../assets/icons/icon-mail.png'
import { MdArrowBack } from "react-icons/md";
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import Dialog from '../../../components/Dialog/Dialog';
import axiosInstance from '../../../api/axiosInstance';
import ResponsiveContainer from '../../../layouts/ResponsiveContainer';
import DialogContent from '../../../components/Dialog/DialogContent';
import { MdCheckCircle } from "react-icons/md";
const { REACT_APP_API_PATH } = process.env;

type TContactUsForm = {
    nombre: string;
    telefono: string;
    correo: string;
    asunto: string;
    consulta: string;
    pertenenciaInstitucional: string
}

const ContactUsForm = () => {
    const { register,
        handleSubmit,
        reset,
        formState: { errors, isValid, isDirty }
    } = useForm<TContactUsForm>({ mode: 'onChange' });
    const navigate = useNavigate();
    const formRef = useRef<HTMLFormElement>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [isSubmitSuccessful, setIsSubmitSuccessful] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    const onSubmit = async (data: TContactUsForm) => {
        if (data) {
            setLoading(true)
            try {
                const response = await axiosInstance.post(`${REACT_APP_API_PATH}/zimbra/sendemail`, data)
                if (response.data) {
                    setIsOpen(true)
                    setIsSubmitSuccessful(true)
                    setLoading(false)
                }
            } catch (error) {
                console.log(error)
            }
            finally {
                setLoading(false)
            }
        }
    };

    useEffect(() => {
        if (isSubmitSuccessful) {
            reset({
                nombre: "", telefono: "", correo: "", asunto: "", consulta: "", pertenenciaInstitucional: ''
            })
        }
    }, [reset, isSubmitSuccessful]);

    const goBack = () => {
        navigate(-1)
    }

    return (
        <div className='flex justify-center'>
            <ResponsiveContainer>
                <Button
                    label='Volver'
                    showIconLeft={true}
                    showIconRight={false}
                    icon={MdArrowBack}
                    className='text-primary-40 mt-8 mb-6'
                    onClick={goBack}
                />
                <div className='flex flex-col items-center gap-3 md:py-8 md:px-6 md:border md:border-[#DCE4E9] md:rounded-xl md:shadow-lg '>
                    <>
                        <img src={mail} alt="icon-mail" />
                        <h1 className='text-hsmall md:text-dsmall text-primary-10'>Contactanos</h1>
                        <p className='text-blarge text-primary-10'>Si tienes alguna duda sobre el monitor, no dudes en comunicarte con nosotros.</p>
                    </>
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)} className='flex flex-col items-center justify-center w-full gap-4 mt-3'>
                        <div className='flex flex-col md:flex-row gap-4 w-full '>
                            <FormInput
                                id='nombre'
                                name='nombre'
                                label='Nombre y apellido*'
                                type='text'
                                placeholder=''
                                register={register}
                                rules={{
                                    required: 'Se debe ingresar el nombre',
                                    pattern: { value: /[a-zA-Z]/, message: 'Se permiten ingresar solo letras' }
                                }}
                                errors={errors}
                            />
                            <FormInput
                                id='phone'
                                name='telefono'
                                aria-placeholder='Ej: 54 11 3123 3123'
                                placeholder='Ej: 54 11 3123 3123'
                                type='text'
                                label='Teléfono'
                                register={register}
                            />
                        </div>
                        <div className='flex  flex-col md:flex-row  justify-center gap-4 w-full'>
                            <FormInput
                                id='email'
                                name='correo'
                                label='Correo*'
                                type='text'
                                placeholder='ejemplo@gmail.com'
                                register={register}
                                rules={{ required: 'Se debe ingresar el correo' }}
                                errors={errors}
                            />
                            <FormInput
                                id='institucion'
                                name='pertenenciaInstitucional'
                                label='Pertenencia institucional (si corresponde)'
                                type='text'
                                placeholder=''
                                register={register}
                            />
                        </div>
                        <FormInput
                            id='subject'
                            name='asunto'
                            label='Asunto*'
                            type='text'
                            placeholder=''
                            register={register}
                            rules={{ required: 'Se debe ingresar el asunto' }}
                            errors={errors}
                        />
                        <FormInput
                            id='consult'
                            name='consulta'
                            label='Consulta*'
                            type='text'
                            placeholder=''
                            register={register}
                            rules={{ required: 'Se debe ingresar la consulta', minLength: { value: 50, message: 'La cantidad mínima de caracteres es de 50' } }}
                            errors={errors}
                            as="textarea"
                        />
                        <div className='w-full flex justify-end'>
                            <Button
                                label='Enviar'
                                type='submit'
                                showIconLeft={false}
                                showIconRight={false}
                                className='bg-primary-40 text-primary-100'
                                disabled={!isDirty || !isValid || loading}
                            />
                        </div>
                    </form>
                </div>
            </ResponsiveContainer>
            <Dialog
                isOpen={isOpen}
                toggle={() => setIsOpen(!isOpen)}>
                <DialogContent
                    title='¡Mensaje enviado con éxito!'
                    message='Pronto recibirás respuesta de nuestro equipo.'
                    icon={MdCheckCircle}
                    colorIcon={'#0E6681'}
                />
            </Dialog>
        </div>
    )
}

export default ContactUsForm