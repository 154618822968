import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../../api/axiosInstance";
import { ExtendedRight, searchOfIndicators } from "../../../../interfaces/Rights";
const { REACT_APP_API_PATH } = process.env;

interface GetExtendedRightResponse {
    extendedRight: ExtendedRight
}

interface GetExtendedRightQuery {
    right: string
}

interface GetSearchOfIndicators {
    search: searchOfIndicators[] | null
    error?: string | undefined
}

interface GetSearchOfIndicatorsQuery {
    indicator: string;
    right: string;
}

interface ErrorResponse {
    message: string;
    // otros campos relevantes del error
}

export const getExtendedRight = createAsyncThunk<GetExtendedRightResponse, GetExtendedRightQuery, { rejectValue: ErrorResponse }>(
    "display/getExtendedRight",
    async ({ right }: GetExtendedRightQuery) => {
        try {
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/derechos?titulo=${right}`)
            const data = response.data
            return {
                extendedRight: data.data
            }
        } catch (error: any) {
            return {
                extendedRight: null
            };
        }
    }
);

export const getSearchOfIndicators = createAsyncThunk<GetSearchOfIndicators, GetSearchOfIndicatorsQuery, { rejectValue: ErrorResponse }>(
    "display/search",
    async ({ indicator, right }: GetSearchOfIndicatorsQuery) => {
        try {
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/indicadores?titulo=${right}&search=${indicator}`)
            const data = response.data
            return {
                search: data.data.data
            }
        } catch (error: any) {
            return {
                search: null,
                error: "No se encontraron indicadores."
            };
        }
    }
);