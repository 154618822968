import React from 'react'
import desktop from '../../assets/img/escritorio.png'
import Button from '../Buttons/Button'
import ResponsiveContainer from '../../layouts/ResponsiveContainer'
import { useNavigate } from 'react-router-dom'

const DesktopOnly = () => {
    const navigate = useNavigate()

    return (
        <div className='block lg:hidden flex items-center justify-center mt-12'>
            <ResponsiveContainer className='flex flex-col items-center gap-6 text-primary-10 '>
                <img src={desktop} alt='Esta página solo puede ser vista en escritorio' />
                <h1 className='text-hsmall text-center'>Esta sección solo está disponible en la versión de escritorio</h1>
                <div className='flex gap-6 flex-col'>
                    <p className='text-blarge'>Para una mejor experiencia, accedé al Monitor de Derechos desde una computadora de escritorio. Estamos trabajando para ofrecer una versión móvil en el futuro.</p>
                    <p className='text-blarge text-left'>Mientras tanto, podés visitar las otras secciones como <span className='font-bold'>Monitor, Investigaciones y acciones, ¿Qué es? y Actividades.</span></p>
                </div>
                <Button
                    label='Volver a monitor'
                    className='bg-system-secondary-10 text-secondary-10'
                    onClick={() => navigate('/monitor')}
                />
            </ResponsiveContainer>
        </div>
    )
}

export default DesktopOnly