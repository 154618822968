// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../assets//img/header-desing(2).png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `nav a {
    text-transform: uppercase;
    color: #40484C;
    font-weight: 700;
    font-size: 12px;
    line-height: 14.06px;
    padding: 1rem 1.50rem;
}

.icon {
    color: inherit;
}

.icon:hover {
    color: none !important;
}

nav a:hover {
    color: #996A5E;
}

nav a.active {
    color: white;
    background-color: #DF6B4E;
    border-radius: 100px;
}

/* HEADER DESING */
.header-desing {
    background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) center no-repeat;
    background-position: left;
    background-size: cover;
}


@media screen and (min-width: 904px) {
    nav a {
        padding: 0;
        color: #DF6B4E;
    }

    nav a:hover {
        color: #996A5E
    }

    nav a.active {
        color: #F6A476;
        background-color: white;
    }

    .icon:hover {
        color: initial !important;
    }
}`, "",{"version":3,"sources":["webpack://./src/layouts/styles.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,cAAc;IACd,gBAAgB;IAChB,eAAe;IACf,oBAAoB;IACpB,qBAAqB;AACzB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,YAAY;IACZ,yBAAyB;IACzB,oBAAoB;AACxB;;AAEA,kBAAkB;AAClB;IACI,oEAAuE;IACvE,yBAAyB;IACzB,sBAAsB;AAC1B;;;AAGA;IACI;QACI,UAAU;QACV,cAAc;IAClB;;IAEA;QACI;IACJ;;IAEA;QACI,cAAc;QACd,uBAAuB;IAC3B;;IAEA;QACI,yBAAyB;IAC7B;AACJ","sourcesContent":["nav a {\n    text-transform: uppercase;\n    color: #40484C;\n    font-weight: 700;\n    font-size: 12px;\n    line-height: 14.06px;\n    padding: 1rem 1.50rem;\n}\n\n.icon {\n    color: inherit;\n}\n\n.icon:hover {\n    color: none !important;\n}\n\nnav a:hover {\n    color: #996A5E;\n}\n\nnav a.active {\n    color: white;\n    background-color: #DF6B4E;\n    border-radius: 100px;\n}\n\n/* HEADER DESING */\n.header-desing {\n    background: url('../assets//img/header-desing(2).png') center no-repeat;\n    background-position: left;\n    background-size: cover;\n}\n\n\n@media screen and (min-width: 904px) {\n    nav a {\n        padding: 0;\n        color: #DF6B4E;\n    }\n\n    nav a:hover {\n        color: #996A5E\n    }\n\n    nav a.active {\n        color: #F6A476;\n        background-color: white;\n    }\n\n    .icon:hover {\n        color: initial !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
