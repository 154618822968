import React from 'react'
import { MdArrowForward } from "react-icons/md";

interface QuickAccessButton {
    label: string,
    disabled: boolean,
    onClick?: () => void;
}

const QuickAccessButton = ({ label, disabled, onClick }: QuickAccessButton) => {
    return (
        <button
            className={`h-[4.125rem] rounded-md bg-system-primary-10 disabled:opacity-50 disabled:cursor-not-allowed ${disabled ? "" : "hover:bg-system-primary-20 cursor-pointer hover:shadow hover:shadow-gray-0/30"}`}
            disabled={disabled}
            onClick={onClick}
        >
            <div className='flex justify-between  items-center p-4'>
                <p className='text-blarge text-black'>{label}</p>
                <MdArrowForward color='#0E6681' size={34} />
            </div>
        </button>
    )
}

export default QuickAccessButton