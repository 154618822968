import Dropdown from '../../../../components/Inputs/Selects/CustomSelect';
import Button from '../../../../components/Buttons/Button';
import { IOptions } from '../../../../interfaces/Rights';

interface ISuperFilter {
    rightSelected: string;
    indicatorTypeSelected: string;
    dimentionSelected: string
    subDimentionSelected: string,
    indicatorSelected: string,
    rightOptions: IOptions[];
    indicatorTypesOptions: IOptions[];
    dimensionOptions: IOptions[];
    subDimensionsOptions: IOptions[];
    indicatorsOptions: IOptions[];
    onSelectDropdownRight: (value: string) => void;
    onSelectDropdownTypeIndicator: (value: string) => void;
    onSelectDropdownDimention: (value: string) => void;
    onSelectDropdownSubDimention: (value: string) => void;
    onSelectDropdownIndicator: (value: string) => void;
    disabledTypeIndicator: boolean;
    disabledDimention: boolean;
    disabledSubDimention: boolean;
    disabledIndicator: boolean;
    enableSendButton: boolean;
    enableCleanButton: boolean;
    sendData: () => void
    cleanOptionsAndQuerys: () => void;
}

const SuperFilter = ({
    rightSelected,
    indicatorTypeSelected,
    dimentionSelected,
    subDimentionSelected,
    indicatorSelected,
    rightOptions,
    indicatorTypesOptions,
    dimensionOptions,
    subDimensionsOptions,
    indicatorsOptions,
    onSelectDropdownDimention,
    onSelectDropdownIndicator,
    onSelectDropdownRight,
    onSelectDropdownSubDimention,
    onSelectDropdownTypeIndicator,
    disabledTypeIndicator,
    disabledDimention,
    disabledIndicator,
    disabledSubDimention,
    enableSendButton,
    enableCleanButton,
    sendData,
    cleanOptionsAndQuerys,
}: ISuperFilter) => {

    return (
        <>
            <div>
                <p className='mb-2 text-tmedium text-primary-40'>SUPER FILTRO</p>
                <div className='flex justify-between gap-4 w-full'>
                    <Dropdown
                        options={rightOptions}
                        onSelect={onSelectDropdownRight}
                        selectedItem={rightSelected}
                        defaultSelectedItem={rightSelected}
                        label='Derecho'
                        className='min-w-[150px] max-w-[150px] xl:min-w-[200px] xl:max-w-[200px]'
                    />
                    <Dropdown
                        options={indicatorTypesOptions}
                        onSelect={onSelectDropdownTypeIndicator}
                        selectedItem={indicatorTypeSelected}
                        defaultSelectedItem='Seleccionar'
                        label='Tipo de indicador'
                        disabled={disabledTypeIndicator}
                        className='min-w-[150px] max-w-[150px] xl:min-w-[200px] xl:max-w-[200px]'
                    />
                    <Dropdown
                        options={dimensionOptions}
                        onSelect={onSelectDropdownDimention}
                        selectedItem={dimentionSelected}
                        defaultSelectedItem='Seleccionar'
                        label='Dimensión'
                        disabled={disabledDimention}
                        className='min-w-[150px] max-w-[150px] xl:min-w-[200px] xl:max-w-[200px]'
                    />
                    <Dropdown
                        options={subDimensionsOptions}
                        onSelect={onSelectDropdownSubDimention}
                        selectedItem={subDimentionSelected}
                        defaultSelectedItem='Seleccionar'
                        label='Subdimensión'
                        disabled={disabledSubDimention}
                        className='min-w-[150px] max-w-[150px] xl:min-w-[200px] xl:max-w-[200px]'
                    />
                    <Dropdown
                        options={indicatorsOptions}
                        onSelect={onSelectDropdownIndicator}
                        selectedItem={indicatorSelected}
                        defaultSelectedItem='Seleccionar'
                        label='Indicadores'
                        disabled={disabledIndicator}
                        className='min-w-[150px] max-w-[150px] xl:min-w-[200px] xl:max-w-[200px]'
                    />
                </div>
            </div>
            <div className='flex justify-end'>
                <Button
                    label='Limpiar'
                    type='button'
                    showIconLeft={false}
                    showIconRight={false}
                    className='text-primary-40 bg-primary-100'
                    disabled={enableCleanButton}
                    onClick={cleanOptionsAndQuerys}
                />
                <Button
                    label='Aplicar filtro'
                    type='button'
                    showIconLeft={false}
                    showIconRight={false}
                    className='text-primary-100 bg-primary-40'
                    disabled={enableSendButton}
                    onClick={sendData}
                />
            </div>
        </>
    )
}

export default SuperFilter