import React, { useState } from 'react';
import Button from '../Buttons/Button';
import { MdOutlineFileDownload } from 'react-icons/md';
import axiosInstance from '../../api/axiosInstance';
import { PDFDocument } from 'pdf-lib';
import { IconType } from 'react-icons';
const { REACT_APP_API_PATH } = process.env;

interface Props {
    fileName: string;
    label?: string;
    className?: string;
    icon?: IconType | null; // Aquí definimos que el ícono debe ser del tipo IconType de react-icons
    iconSize?: number
}

const DownloadPDFWithFetch: React.FC<Props> = ({ fileName, label = 'Descargar', className, icon = MdOutlineFileDownload, iconSize = 16 }) => {
    const [isLoading, setIsLoading] = useState(false);

    const handleDownload = async () => {
        setIsLoading(true);
        try {
            // Llama a la API para obtener el PDF en formato array buffer
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/indicadores/pdf?filename=${fileName}`);
            const bytes = Uint8Array.from(atob(response.data.data), c => c.charCodeAt(0));
            const pdfDoc = await PDFDocument.load(bytes);
            const pdfBytes = await pdfDoc.save();
            const url = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${fileName}`);

            document.body.appendChild(link);
            link.click();

            URL.revokeObjectURL(url);
            document.body.removeChild(link);

        } catch (error) {
            console.error('Error al descargar el archivo PDF:', error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Button
            onClick={handleDownload}
            label={isLoading ? 'Descargando...' : label}
            as='link'
            icon={icon ? icon : undefined} // Pasar el ícono renderizado
            showIconLeft={true}
            showIconRight={false}
            className={`${className}`}
            disabled={isLoading}
            iconSize={iconSize}
        />
    );
};

export default DownloadPDFWithFetch;
