import { IMediaAndContent } from '../../interfaces/MediaAndContent'
import DateWithIcon from '../Dates/DateWithIcon';
import ChipsMapper from '../Mappers/ChipsMapper';
import Images from '../Images/Images';

const MediaAndContentLarge = ({ imagen, imagen_base64, titulo, descripcion, categorias, fecha }: IMediaAndContent) => {

    return (
        <div className='md:h-[17.375rem] flex flex-col md:flex-row gap-4 border border-system-light-20 rounded-[12px] bg-system-light-10 cursor-pointer hover:shadow hover:shadow-gray-0/30'>
            <Images imagen={imagen} imagen_base64={imagen_base64} className='h-[9.125rem] md:h-full md:w-[16.25rem] object-cover rounded-t-lg md:rounded-[12px]' />
            <div className='h-[16rem] lg:h-full flex flex-col justify-between px-4 md:p-0'>
                <div className='md:pr-4 md:py-6 flex flex-col gap-2 md:gap-4'>
                    <h2 className='text-tlarge text-system-light-100'>{titulo}</h2>
                    <ChipsMapper categorias={categorias} />
                    <p className='text-bmedium text-system-light-100 lg:text-system-light-40 line-clamp-3'>{descripcion}</p>
                </div>
                <div className='flex gap-6 mt-2 md:mt-0 mb-6'>
                    <DateWithIcon date={fecha} />
                </div>
            </div>
        </div>
    )
}

export default MediaAndContentLarge