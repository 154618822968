import React, { useState } from 'react'
import { MdArrowDropDown } from "react-icons/md";
import { MdArrowDropUp } from "react-icons/md";

const Accordion = ({ title, answer, isOpen }: { title: string, answer: string, isOpen: boolean }) => {
    const [open, setOpen] = useState<boolean>(isOpen);

    const handleOpen = () => {
        setOpen(!open)
    }

    return (
        <>
            <div
                className={`flex flex-col border rounded-md w-full p-4 ${open ? 'bg-system-light-10 border-secondary-40' : 'border-system-light-50'}`}
            >
                <button className='flex justify-between items-center w-full' onClick={handleOpen}>
                    <span className={`text-left text-blarge ${open ? 'text-secondary-40 ' : 'text-system-light-100'}`}>
                        {title}
                    </span>

                    {open ? <MdArrowDropUp /> : <MdArrowDropDown />}
                </button>
                {
                    open
                        ?
                        <div className='px-4 my-2 transition duration-700 ease-in-out whitespace-pre-line'>
                            <span className='b-medium text-system-light-40 '>{answer}</span>
                        </div>
                        : null
                }
            </div>
        </>
    )
}

export default Accordion