import ResponsiveContainer from '../../../../layouts/ResponsiveContainer';
import ContainerGraphs from '../Graphics/ContainerGraphs';
import { IIndicator } from '../../../../interfaces/Indicator';
import HeaderIndicator from './HeaderIndicator';
import FooterIndicator from './FooterIndicator';
import CTABox from '../../../../components/Cards/CTABox';
import { MdArrowForward } from 'react-icons/md';
import NoGraphics from '../Graphics/NoGraphics';

const Indicator = ({ breadcrumbs, titulo, state, descripcion, notas, fuente, graphics, variable_indicador, data, documento_informativo, investigaciones, id }: IIndicator) => {

    return (
        <ResponsiveContainer className='flex flex-col gap-6 border-b-2  border-[#DCE4E9] pb-8 mt-8 '>
            <HeaderIndicator
                breadcrumbs={breadcrumbs}
                titulo={titulo}
                state={state}
                descripcion={descripcion}
                notas={notas}
                documento_informativo={documento_informativo}
                id={id || 0}
            />
            {
                graphics && graphics.length > 0 ?
                    <ContainerGraphs
                        breadcrumbs={breadcrumbs}
                        graphics={graphics}
                        source={fuente ?? ''}
                        variables_indicator={variable_indicador ?? []}
                        titulo={titulo}
                        data={data ?? []}
                    />
                    : <NoGraphics />
            }
            {fuente && <FooterIndicator fuente={fuente} />}
            {
                investigaciones && investigaciones.length > 0 ?
                    <CTABox
                        title="Investigaciones y acciones relacionadas"
                        linkPath="/monitor/investigaciones-y-acciones"
                        buttonLabel="Conocer"
                        buttonIcon={MdArrowForward}
                        background="bg-[#9EF2E4]/30 "
                    /> : null
            }
        </ResponsiveContainer>
    )
}

export default Indicator