import '../styles.css'
import { useEffect } from "react";
import DialogLayout from "./DialogLayout";

interface IDialog {
    isOpen: boolean;
    toggle: () => void;
    children: React.ReactNode
}

const Dialog = ({ isOpen, toggle, children }: IDialog) => {
    useEffect(() => {
        const body: HTMLBodyElement | null = document.querySelector('body');
        const html: HTMLHtmlElement | null = document.querySelector('html');
        if (body && html) {
            body.style.overflow = isOpen ? 'hidden' : '';
            body.style.height = isOpen ? '100%' : '';
            html.style.height = isOpen ? '100%' : '';
            return (() => {
                html.style.height = ''
            })
        }
    }, [isOpen])

    if (!isOpen) return null

    return (
        <DialogLayout onClose={toggle}>
            {children}
        </DialogLayout>
    )
}

export default Dialog