import { Input, InputProps } from './Input';
import { DeepMap, DeepPartial, FieldError, FieldValues, Path, RegisterOptions, UseFormRegister } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { get } from 'lodash';

export type FormInputProps<TFormValues extends FieldValues> = {
    name: Path<TFormValues>;
    rules?: RegisterOptions<TFormValues>;
    register?: UseFormRegister<TFormValues>;
    errors?: DeepMap<DeepPartial<TFormValues>, FieldError>;
} & Omit<InputProps, 'name'>;

export const FormInput = <TFormValues extends FieldValues>({
    className = '',
    name,
    register,
    rules,
    errors,
    ...props
}: FormInputProps<TFormValues>): JSX.Element => {
    const errorMessages = get(errors, name);
    const hasError = !!errorMessages;

    return (
        <div className={`w-full ${className}`} aria-live="polite">
            <Input
                name={name}
                {...props}
                {...(register && register(name, rules))}
                error={hasError}
                aria-invalid={hasError}
            />
            {hasError && (
                <ErrorMessage
                    errors={errors}
                    name={name as any}
                    render={({ message }) => (
                        <p className="mt-1 text-bsmall block text-[#BA1A1A]">
                            {message}
                        </p>
                    )}
                />
            )}
        </div>
    );
};