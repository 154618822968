import React from 'react'
import ContactUsForm from './components/ContactUsForm'

const Index = () => {
    return (
        <>
            <ContactUsForm />
        </>
    )
}

export default Index