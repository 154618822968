import ResponsiveContainer from '../../../layouts/ResponsiveContainer'
import CustomSelects, { CustomSelectsOption } from '../../../components/Inputs/Selects/CustomSelect'
import Evaluations from '../../../components/Evaluations/Evaluations'
import { ArithmeticalEvaluation } from '../../../interfaces/Evaluations'
import Loading from '../../../components/Loading/Loading'

interface IEvaluationCard {
    arithmeticalEvaluation: ArithmeticalEvaluation[]
    periodOptions: CustomSelectsOption[]
    defaultSelectedItemSelect: string,
    trivalent_evaluation_right: -1 | 0 | 1 | null
    onSelectPeriod: (value: string) => void
    selectedPeriod: string
    loading: "idle" | "pending" | "succeeded" | "failed";
}

const EvaluationCard = ({
    arithmeticalEvaluation,
    periodOptions,
    defaultSelectedItemSelect,
    trivalent_evaluation_right,
    onSelectPeriod,
    selectedPeriod,
    loading
}: IEvaluationCard) => {
    return (
        <ResponsiveContainer className='bg-primary-100 relative z-[345] evaluation-and-filter-card top-40 p-4 rounded-lg shadow-lg flex flex-col gap-6 mb-16'>
            <div className='w-[160px] flex items-end gap-2'>
                <CustomSelects
                    label='Período'
                    options={periodOptions}
                    onSelect={onSelectPeriod}
                    selectedItem={selectedPeriod}
                    defaultSelectedItem={defaultSelectedItemSelect}
                />
                {
                    loading === 'pending'
                        ? <Loading size={26} color="rgb(14 102 129)" />
                        : null
                }
            </div>
            <Evaluations
                arithmeticalEvaluation={arithmeticalEvaluation}
                showArithmeticalIndicators={true}
                arithmeticalEvaluationLabel='Cantidad de indicadores por estado evaluados'
                trivalentEvaluation={trivalent_evaluation_right}
                trivalentStateStyle='text-llarge'
                evaluationStyle='text-hsmall'
                backgroundColorCard='#F5FAFD'
                showTitleEvaluation={true}
            />
        </ResponsiveContainer>
    )
}

export default EvaluationCard