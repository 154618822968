import EvaluationStates from "../States/EvaluationStatesCircle";

interface ChipState {
    state: number | null,
}

const ChipState = ({ state }: ChipState) => {

    return (
        <div className='rounded-lg bg bg-primary-10 flex gap-3 items-center h-[40px] w-[175px]'>
            <div className='py-2 px-3 flex justify-between gap-3' >
                <span className='text-primary-100 text-tsmall'> Estado del período </span>
                <EvaluationStates status={state} size={18} />
            </div>
        </div>
    )
}

export default ChipState