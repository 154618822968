import React from 'react'
import { MdOutlineClose } from 'react-icons/md'

type TDialogLayout = {
    onClose: () => void;
    children: React.ReactNode
}

const DialogLayout = ({ onClose, children }: TDialogLayout) => {
    return (
        <div className="container-dialog fixed top-0 left-0 w-full h-full">
            <div className="container-inner bg-system-light-60">
                <div className='px-6 py-4'>
                    <button onClick={onClose} className="flex w-full justify-end">
                        <MdOutlineClose size={24} />
                    </button>
                    {children}
                </div>
            </div>
        </div>
    )
}

export default DialogLayout