import { MdClear, MdCheck } from "react-icons/md";
import { IconType } from 'react-icons';
import { hexToRgb } from "../../utils/utils";

interface StatusConfig {
    colorClass: string;
    backgroundColor: string;
    icon?: IconType | null;
    label: string;
}

const statusConfig: Record<string, StatusConfig> = {
    'Cumple': {
        colorClass: 'text-indicator-green',
        backgroundColor: "#03D68A",
        icon: MdCheck,
        label: 'Cumple',
    },
    Restringe: {
        colorClass: 'text-indicator-red',
        backgroundColor: "#FF6060",
        icon: MdClear,
        label: 'Restringe',
    },
    '-': {
        colorClass: 'text-system-light-50',
        backgroundColor: '#70787D',
        icon: null,
        label: 'No se evalúa',
    },
    'No Aplica': {
        colorClass: 'text-indicator-null',
        backgroundColor: '#FFC0CB',
        icon: null,
        label: '-',
    }
};

const EvaluationLaws = ({ evaluacion }: { evaluacion: string | null }) => {
    const { colorClass, backgroundColor, icon: Icon, label } = statusConfig[evaluacion || '-'] || statusConfig['-'];
    const backgroundRgb = hexToRgb(backgroundColor);

    return (
        <div
            className={`flex items-center gap-1 py-0.5 px-2 ${colorClass} w-[140px] justify-center rounded-md`}
            style={{ backgroundColor: `rgba(${backgroundRgb}, 0.1)` }}
        >
            {Icon && <Icon />}
            <span className={`${colorClass} text-bmedium`}>{label}</span>
        </div>
    );
};

export default EvaluationLaws;