// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/img/background-hero.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* HERO */
.hero {
    width: 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    top: 0;
    /* background-color: #E4FFF9; */
}

.img-hero {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    /* background-color: #E4FFF9; */
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
    opacity: 0.15;
    background-repeat: no-repeat;
    background-size: cover;
}

.shape {
    height: 750px;
    clip-path: polygon(0 0, 100% 0, 100% 82%, 50% 100%, 0 82%);
}

.without-shape {
    height: 33.625rem;
}

@media screen and (min-width: 600px) {
    .shape {
        height: 540px;
    } 

    .without-shape {
        height: 25.813rem;
    }
}

@media screen and (min-width: 905px) {
    .shape {
        height: 560px;
    }

    .without-shape {
        height: 21.25rem;
    }
}`, "",{"version":3,"sources":["webpack://./src/components/Hero/styles.css"],"names":[],"mappings":"AAAA,SAAS;AACT;IACI,WAAW;IACX,kBAAkB;IAClB,SAAS;IACT,OAAO;IACP,MAAM;IACN,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;IAClB,QAAQ;IACR,OAAO;IACP,WAAW;IACX,+BAA+B;IAC/B,yDAA6D;IAC7D,aAAa;IACb,4BAA4B;IAC5B,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,0DAA0D;AAC9D;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,iBAAiB;IACrB;AACJ;;AAEA;IACI;QACI,aAAa;IACjB;;IAEA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":["/* HERO */\n.hero {\n    width: 100%;\n    position: absolute;\n    bottom: 0;\n    left: 0;\n    top: 0;\n    /* background-color: #E4FFF9; */\n}\n\n.img-hero {\n    position: absolute;\n    top: 0px;\n    left: 0;\n    width: 100%;\n    /* background-color: #E4FFF9; */\n    background-image: url('../../assets/img/background-hero.png');\n    opacity: 0.15;\n    background-repeat: no-repeat;\n    background-size: cover;\n}\n\n.shape {\n    height: 750px;\n    clip-path: polygon(0 0, 100% 0, 100% 82%, 50% 100%, 0 82%);\n}\n\n.without-shape {\n    height: 33.625rem;\n}\n\n@media screen and (min-width: 600px) {\n    .shape {\n        height: 540px;\n    } \n\n    .without-shape {\n        height: 25.813rem;\n    }\n}\n\n@media screen and (min-width: 905px) {\n    .shape {\n        height: 560px;\n    }\n\n    .without-shape {\n        height: 21.25rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
