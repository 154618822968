import React from 'react';

interface LoadingProps {
  size?: number; // Tamaño del spinner
  className?: string; // Clases CSS adicionales
  color?: string; // Color del spinner
}

const Loading = ({
  size = 50, // Valor por defecto para el tamaño
  className = '',
  color = 'black', // Valor por defecto para el color
}: LoadingProps) => {
  const spinnerStyle: React.CSSProperties = {
    width: size,
    height: size,
    border: `4px solid ${color}`,
    borderColor: `${color} ${color} ${color} transparent`,
  };

  return (
    <div className={`w-full flex items-center justify-center ${className}`}>
      <div className="loader" style={spinnerStyle}></div>
    </div>
  );
};

export default Loading;