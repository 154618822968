import { useState } from 'react'
import ArithmeticalEvaluation from './ArithmeticalEvaluation'
import TitleEvaluations from './TitleEvaluations'
import TrivalentEvaluation from './TrivalentEvaluation'
import { MdOutlineInfo } from "react-icons/md";
import Popover from '../Popover/Popover'
import PopoverContent from '../Popover/PopoverContent'
import { IArithmeticalEvaluation } from '../../interfaces/Rights';

interface IEvaluations {
    arithmeticalEvaluation: IArithmeticalEvaluation[]
    showArithmeticalIndicators: boolean
    arithmeticalEvaluationLabel: string
    trivalentEvaluation: number | null
    trivalentStateStyle: string
    evaluationStyle: string
    backgroundColorCard: string
    showTitleEvaluation: boolean
}

/**
 * Component showing trivalent and arithmetical evaluation
 */
const Evaluations = ({
    arithmeticalEvaluation,
    showArithmeticalIndicators,
    arithmeticalEvaluationLabel,
    trivalentEvaluation,
    trivalentStateStyle,
    evaluationStyle,
    backgroundColorCard,
    showTitleEvaluation
}: IEvaluations) => {

    const [isModalVisible, setIsModalVisible] = useState(false)
    const [modalContent, setModalContent] = useState({ title: '', content: '' })
    const [popoverPosition, setPopoverPosition] = useState({ top: 0, left: 0 })

    const openModal = (title: string, content: string, posTop: number, posLeft: number) => {
        if (!showTitleEvaluation) return
        setModalContent({ title, content })
        setPopoverPosition({
            top: posTop,
            left: posLeft
        })
        setIsModalVisible(true)
    }

    const closeModal = () => {
        setIsModalVisible(false)
    }

    return (
        <div className='relative'>
            <div className='flex flex-col sm:flex-row gap-3 w-full h-full '>
                <div className='flex flex-col w-full gap-3 relative'>
                    {
                        showTitleEvaluation
                        && <TitleEvaluations
                            label='Evaluación aritmética'
                            onClick={() => openModal(
                                'Evaluación aritmética',
                                `Con esta barra te mostramos - en verde, rojo o amarillo - la cantidad total de los indicadores que en términos individuales registraron mejoras, retrocesos o se mantuvieron iguales.
                                Como se trata de información desagregada, puede no coincidir con el estado general de cumplimiento del derecho, que refleja la evaluación “Trivalente”.`,
                                0, 175
                            )}
                        />
                    }
                    <ArithmeticalEvaluation
                        data={arithmeticalEvaluation}
                        showIndicators={showArithmeticalIndicators}
                        label={arithmeticalEvaluationLabel}
                        backgroundColor={backgroundColorCard}
                    />
                </div>
                <div className='flex flex-col w-full gap-3  relative'>
                    {
                        showTitleEvaluation
                        && <TitleEvaluations
                            label='Evaluación trivalente'
                            onClick={() => openModal(
                                'Evaluación trivalente',
                                `Asigna a cada indicador uno de tres valores posibles calificando con 1 la mejora, -1 el retroceso y 0 la neutralidad. Posteriormente, según la suma de los mismos resulte en un número positivo, negativo o igual a cero se escalan de forma piramidal en el siguiente orden: sub-dimensión, dimensión, tipo de indicadores y derecho. 
                                De ese modo, si, por ejemplo, el resultado de la suma de cuatro indicadores de una sub-dimensión fuera igual a 2 (1+1+1-1), se la consideraría una mejora y se le asignaría un valor positivo, igual a 1. Y así sucesivamente en los distintos niveles hasta alcanzar el estado general del derecho.`,
                                0, 600
                            )}
                        />
                    }
                    <TrivalentEvaluation
                        evaluationRight={trivalentEvaluation}
                        stateStyleText={trivalentStateStyle}
                        evaluationStateStyleText={evaluationStyle}
                        bgColor={backgroundColorCard}
                    />
                </div>

            </div>
            <Popover
                isOpen={isModalVisible}
                toggle={closeModal}
                position={{ top: `${popoverPosition.top}px`, left: `${popoverPosition.left}px` }}
            >
                <PopoverContent
                    title={modalContent.title}
                    message={modalContent.content}
                    icon={MdOutlineInfo}
                    colorIcon='#0E6681'
                />
            </Popover>
        </div>
    )
}

export default Evaluations