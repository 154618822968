import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { getPeriods } from './periodsThunk';
import { Period } from '../../../interfaces/Periods';

interface PeriodsState {
    periods: Period[] | null,
    loading: "idle" | "pending" | "succeeded" | "failed";
    lastPeriod: string | null
}

const initialState: PeriodsState = {
    loading: 'idle',
    periods: null,
    lastPeriod: '2023' 
}

export const periodsSlice = createSlice({
    name: 'periods',
    initialState,
    reducers: {
        setSelectedPeriod: (state, action: PayloadAction<{ period: string }>) => {
            state.lastPeriod = action.payload.period
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPeriods.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getPeriods.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.periods = action.payload.periods;
            state.lastPeriod = action.payload.periods.at(-1).value.split(" ").pop() || ''
        });
        builder.addCase(getPeriods.rejected, (state) => {
            state.loading = "failed";
        });
    }
})

export const { setSelectedPeriod } = periodsSlice.actions;

export default periodsSlice.reducer;