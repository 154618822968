import React, {
    FC,
    forwardRef,
    DetailedHTMLProps,
    InputHTMLAttributes,
    TextareaHTMLAttributes,
} from 'react';

export type InputSize = 'medium' | 'large';
export type InputType = 'text' | 'email';

export type InputProps = {
    id: string;
    name: string;
    label: string;
    type?: InputType;
    size?: InputSize;
    className?: string;
    error?: boolean;
    as?: 'input' | 'textarea';
} & Omit<
    DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> &
    DetailedHTMLProps<TextareaHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    'size'
>;

export const Input: FC<InputProps> = forwardRef<
    HTMLInputElement & HTMLTextAreaElement,
    InputProps
>(
    (
        {
            id,
            name,
            label,
            type = 'text',
            size = 'medium',
            className = '',
            placeholder,
            error,
            as = 'input',
            ...props
        },
        ref
    ) => {
        const commonProps = {
            id,
            ref,
            name,
            'aria-label': label,
            placeholder,
            className: `relative h-14 inline-flex w-full rounded-sm text-blarge placeholder-system-light-40
                      text-system-light-100 border 
                      hover:border-system-light-100 
                      focus:outline-none focus:border-primary-40 px-4 py-3 h-max
                      ${error !== true ? 'border-system-light-50' : 'border-[#BA1A1A]'} 
                      ${className}`,
            ...props,
        };

        return (
            <>
                <label htmlFor={id} className='text-system-light-40 text-bsmall'>{label}</label>
                {as === 'input' ? (
                    <input
                        type={type}
                        {...commonProps}
                    />
                ) : (
                    <textarea
                        rows={6}
                        {...commonProps}
                    />
                )}
            </>
        );
    }
);