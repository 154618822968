import React from 'react'
import workTeam from '../../../assets/img/people-collaborating-in-the-workplace.png'
import ResponsiveContainer from '../../../layouts/ResponsiveContainer'

const WorkTeam = () => {
    return (
        <div className='flex justify-center bg-system-light-10 w-full py-20 mt-28'>
            <ResponsiveContainer className='flex flex-col lg:flex-row justify-between items-center'>
                <div className='lg:w-[28.5rem] flex flex-col gap-4'>
                    <h2 className='text-dsmall text-primary-10'>Equipo de trabajo</h2>
                    <p className='text-blarge text.primary-10'>El equipo del Monitor de Derechos, integrado por profesionales de distintas disciplinas y especialidades, forma parte de la Defensoría del Pueblo de la Ciudad Autónoma de Buenos Aires. Nuestra labor está enfocada en recopilar, visualizar y analizar información crucial sobre la situación de los derechos humanos en el territorio porteño, con perspectiva de género. De esa manera, buscamos promover avances concretos en materia de justicia social e igualdad.</p>
                </div>
                <img src={workTeam} alt='trabajo en equipo' className='w-[22.5rem] h-[17.4rem]' />
            </ResponsiveContainer>
        </div>
    )
}

export default WorkTeam