import Titles from '../../../components/Titles/Titles'
import Button from '../../../components/Buttons/Button'
import { MdAdd } from "react-icons/md";
import { IMediaAndContent } from '../../../interfaces/MediaAndContent';
import MediaAndContentMapper from '../../../components/Mappers/MediaAndContentMapper';
import { Link } from 'react-router-dom';
import ResponsiveContainer from '../../../layouts/ResponsiveContainer';

interface IFeaturedActivities {
    activities: IMediaAndContent[] | null;
}

const FeaturedActivities = ({ activities }: IFeaturedActivities) => {
    return (
        <div className="flex flex-col justify-center w-full items-center mt-16">
            <ResponsiveContainer className='flex flex-col gap-6'>
                <Titles title='Actividades destacadas' />
                <MediaAndContentMapper data={activities} viewType='mini' />
                <div className='flex justify-center'>
                    <Link to='/monitor/actividades'>
                        <Button label='Más actividades' icon={MdAdd} className='bg-system-secondary-10' />
                    </Link>
                </div>
            </ResponsiveContainer>
        </div>
    )
}

export default FeaturedActivities