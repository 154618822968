import React from 'react'
import '../styles.css'

const NumberCharts = ({ text, number }: { text: string, number: number }) => {
    return (
        <div className='container-charts'>
            <div className='flex flex-col gap-6 absolute top-[-10px]'>
                <p className='number'>{number}.</p>
                <p className='text-tmedium text-system-light-40 w-[13.25rem] text-center'>{text}</p>
            </div>
        </div>
    )
}

export default NumberCharts