import EvaluationStatusCircle from '../States/EvaluationStatesCircle'

interface IEvaluationTypeIndicator {
    status: number | null;
    size: number;
    label: string;
    isSelected: boolean;
    handleClick: () => void;
}

const EvaluationTypeIndicator = ({ status, size, label, isSelected, handleClick }: IEvaluationTypeIndicator) => {
    return (
        <div
            onClick={handleClick}
            className={`rounded-xl shadow-lg  shadow-gray-0/20 p-4 cursor-pointer w-full ${isSelected ? 'bg-primary-10 text-primary-100' : 'bg-system-light-10 text-primary-40  hover:bg-system-light-60'} `}
        >
            <div className='flex gap-3 items-center justify-center'>
                <EvaluationStatusCircle status={status} size={size} />
                <span className='text-llarge '>{label}</span>
            </div>
        </div>
    );
};

export default EvaluationTypeIndicator;
