import React from 'react'
import { ICategories } from '../../interfaces/Rights'
import Chips from '../Chips/Chips'

const ChipsMapper = ({ categorias }: { categorias: ICategories[] }) => {
    return (
        <div className='flex flex-wrap gap-x-4 gap-y-1'>
            {
                categorias && categorias.map((categoria) => (
                    <Chips label={categoria.titulo} key={categoria.id} color={categoria.color} />
                ))
            }
        </div>
    )
}

export default ChipsMapper