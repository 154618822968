import React from 'react';
import StackedBarChart from '../../../../components/Charts/StackedBarChart';
import CompositeBarsCharts from '../../../../components/Charts/CompositeBarsCharts';
import PieCharts from '../../../../components/Charts/PieCharts';
import LineCharts from '../../../../components/Charts/LineCharts';
import { BaseMap, ChartData, GraphicsDomain } from '../../../../interfaces/Indicator';
import BarChartt from '../../../../components/Charts/BarChart';
import CustomMap from '../../../../components/Maps/Maps';
import HeatMap from '../../../../components/Maps/HeatMap';

interface IndicatorGraphs {
  typeOfGraph: 1 | 2 | 3 | 4 | 5 | 6 | number;
  data: ChartData[] | BaseMap[];
  chartRef: React.RefObject<HTMLDivElement>,
  typeOfIndicator: string;
  variable_analisis: string[];
  domain?: GraphicsDomain | null;
  errorsNoData: boolean
}

const GRAFICO_BARRA = 1;
const GRAFICO_BARRA_APILABLE = 2;
const GRAFICO_BARRA_AGRUPADA = 3;
const GRAFICO_TORTA = 5;
const GRAFICO_LINEAL = 4;
const MAPA = 6
const MAPA_CALOR = 7

const IndicatorGraphs = ({ typeOfGraph, data, chartRef, typeOfIndicator, variable_analisis, domain, errorsNoData }: IndicatorGraphs) => {
  switch (typeOfGraph) {
    case GRAFICO_BARRA:
      return <>
        <BarChartt
          data={data as ChartData[]}
          typeOfIndicador={typeOfIndicator}
          variable_analisis={variable_analisis}
          ref={chartRef}
          range={domain}
        />
      </>;
    case GRAFICO_BARRA_APILABLE:
      return <StackedBarChart
        data={data as ChartData[]}
        ref={chartRef}
        variable_analisis={variable_analisis}
        range={domain}
      />;
    case GRAFICO_BARRA_AGRUPADA:
      return <CompositeBarsCharts
        data={data as ChartData[]}
        variable_analisis={variable_analisis}
        ref={chartRef}
        range={domain}
      />;
    case GRAFICO_TORTA:
      return <PieCharts
        data={data as ChartData[]}
        ref={chartRef}
      />;
    case GRAFICO_LINEAL:
      return <LineCharts
        data={data as ChartData[]}
        typeOfIndicador={typeOfIndicator}
        variable_analisis={variable_analisis}
        ref={chartRef}
        range={domain}
      />;
    case MAPA:
      return <CustomMap
        data={data as BaseMap[]}
        mapRef={chartRef}
        errorsNoData={errorsNoData}
      />
    case MAPA_CALOR:
      return <HeatMap
        data={data as BaseMap[]}
        mapRef={chartRef}
        errorsNoData={errorsNoData}
      />
    default:
      return <></>;
  }
};

export default IndicatorGraphs;
