import { MdChevronLeft } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";

type PaginationProps<T> = {
    data: T[];
    totalPages: number;
    currentPage: number;
    setCurrentPage: (page: number) => void
};

const Pagination = <T,>({ data, totalPages, currentPage, setCurrentPage }: PaginationProps<T>) => {

    const handlePreviousPage = () => {
        setCurrentPage(currentPage - 1);
        scrollToTop();
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
        scrollToTop();
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <>
            {
                data?.length > 0 ?
                    <div className="mb-6 flex items-center justify-center mt-4">
                        <div className="flex items-center justify-center gap-3">
                            <button className={`page-item previous ${currentPage <= 1 ? 'disabled:opacity-25 cursor-not-allowed ' : ''}`} onClick={() => handlePreviousPage()} disabled={currentPage === 1}>
                                <MdChevronLeft size={28} />
                            </button>
                            <p>
                                <span className='text-tmedium text-primary-0 '>{currentPage}</span>
                                <span className='mx-3'>/</span>
                                <span className='text-blarge text-system-light-50 '>{totalPages}</span>
                            </p>
                            <button className={`page-item next ${currentPage >= totalPages ? 'disabled:opacity-25 cursor-not-allowed' : ''}`} onClick={() => handleNextPage()} disabled={currentPage === totalPages}>
                                <MdChevronRight size={28} />
                            </button>
                        </div>
                    </div>
                    : null
            }
        </>
    )
}

export default Pagination