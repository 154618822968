import Hero from '../../components/Hero/Hero'
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import SEO from '../../components/SEO/SEO'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../../redux/hooks'
import { useEffect, useState } from 'react'
import { hexToRgb } from '../../utils/utils'
import { UPLOAD } from '../../utils/const'
import { getArithmeticalEvaluation, getEvaluationRight } from '../../redux/slices/evaluator/evaluatorThunk'
import EvaluationCard from './components/EvaluationCard'
import { clearEvaluation, clearTypeOfIndicator } from '../../redux/slices/evaluator/evaluatorSlice'
import EvaluationTypeOfIndicator from './components/EvaluationTypeOfIndicator'
import EvaluationDimension from './components/EvaluationDimension'
import { MdArrowForward } from 'react-icons/md'
import CTABox from '../../components/Cards/CTABox'
import { getExtendedRight } from '../../redux/slices/indicatorDisplay/SearchAndFilter/filterAndSearchThunk'
import { getPeriods } from '../../redux/slices/periods/periodsThunk'
import DesktopOnly from '../../components/DesktopOnly/DesktopOnly'
import Warning from '../../components/Warning/Warning'
const { REACT_APP_BACKOFFICE_CONNECTION } = process.env;

const Index = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [period, setPeriod] = useState<string>('');
    const [selectedPeriod, setSelectedPeriod] = useState<string>('')
    const [isScreenLarge, setIsScreenLarge] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(true);

    const {
        loading_arithmetical,
        evaluation_right,
        arithmetical_evaluation_right,
        trivalent_evaluation_right,
        evaluation_indicator_type,
        evaluation_dimension,
        evaluation_subdimension,
        selected_type_indicator,
    } = useAppSelector((state) => state.evaluator);

    const { periods } = useAppSelector((state) => state.periods)

    useEffect(() => {
        const params = new URLSearchParams(location.search);
        const right = params.get('rightId') || '';
        const period = params.get('periodo') || '';

        if (!evaluation_right && !arithmetical_evaluation_right) {
            dispatch(getEvaluationRight({ right, period: period }))
            dispatch(getArithmeticalEvaluation({ right: right, period: period }))
            dispatch(getExtendedRight({ right: params.get('right') || '' }))
            setPeriod(period)
            setSelectedPeriod(`Período ${period}`)
        }
    }, [location.search, dispatch])

    useEffect(() => {
        if (!periods) {
            dispatch(getPeriods())
        }
    }, [periods])

    useEffect(() => {
        return () => {
            dispatch(clearEvaluation())
        }
    }, [])

    //Que no aparezca el modal cuando la pantalla es más grande de 1280
    useEffect(() => {
        const checkScreenSize = () => {
            setIsScreenLarge(window.innerWidth > 1280);
        };

        checkScreenSize();

        window.addEventListener('resize', checkScreenSize);

        return () => {
            window.removeEventListener('resize', checkScreenSize);
        };
    }, []);

    const onSelectPeriod = (value: string) => {
        if (value.length) {
            const year = value.split(' ')[1];
            setSelectedPeriod(`Período ${year}`);

            const params = new URLSearchParams(location.search);
            const right = params.get('rightId') || '';

            dispatch(clearTypeOfIndicator());

            dispatch(getEvaluationRight({ right, period: year }))
            dispatch(getArithmeticalEvaluation({ right, period: year }))
            params.delete('periodo');
            params.set('periodo', year);
            navigate({ search: params.toString() });
        }
    }

    if (!evaluation_right) return <div>Cargando...</div>

    return (
        <>
            <SEO
                title={`Evaluador de ${evaluation_right?.titulo}`}
                description='Evaluador de derechos'
                type='article'
            />
            {/* PERMITE VER AL EVALUADOR SOLO EN > A 1280 */}
            <DesktopOnly />
            <div className="hidden lg:block">
                <Warning
                    isOpen={isModalOpen && isScreenLarge}
                    setIsOpen={setIsModalOpen}
                />
                <Hero
                    breadcrumbs={<Breadcrumbs />}
                    title={`Evaluador de ${evaluation_right?.titulo}`}
                    paragraph={evaluation_right?.descripcion}
                    withShape={true}
                    layout="row"
                    backgroundClassName={{ backgroundColor: `rgb(${hexToRgb(evaluation_right?.color)}, 0.15)` }}
                    imageContainerClassName={{ backgroundColor: `${evaluation_right?.color}` }}
                    media={{
                        type: 'image',
                        src: `${REACT_APP_BACKOFFICE_CONNECTION}${UPLOAD}${evaluation_right?.imagen}`,
                        url: '',
                        alt: 'visualizador',
                        className: 'w-[304px] h-[172px] xl:w-[360px] xl:h-[204px] object-contain  xl:object-cover'
                    }}
                />
                <EvaluationCard
                    trivalent_evaluation_right={trivalent_evaluation_right ?? null}
                    arithmeticalEvaluation={arithmetical_evaluation_right ?? []}
                    periodOptions={periods ?? []}
                    defaultSelectedItemSelect={`Período ${period}`}
                    onSelectPeriod={onSelectPeriod}
                    selectedPeriod={selectedPeriod}
                    loading={loading_arithmetical}
                />
                <EvaluationTypeOfIndicator
                    evaluation_indicator_type={evaluation_indicator_type ?? []}
                />
                <EvaluationDimension
                    evaluation_dimension={evaluation_dimension ?? []}
                    selected_type_indicator={selected_type_indicator}
                    evaluation_subDimension_RES_PROC={evaluation_subdimension ?? []}
                />
                <CTABox
                    title="¿Tenés dudas o consultas?"
                    linkPath="/monitor/contactanos"
                    buttonLabel="Contactanos"
                    buttonIcon={MdArrowForward}
                    background="bg-[#D7D9E5]/30 "
                />
            </div>
        </>
    )
}

export default Index