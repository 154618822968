export const colors = [
    '#81B4DF',
    '#F49C6C',
    '#3AA492',
    '#E3C076',
    '#86C47C',
    '#E7DAA3',
    '#F49C6C',
    '#E76B5D',
    '#8F6CAE',
    '#BE5B82',
    '#1F4550',
    '#7AAAB0',
    '#0068B2',
    '#9D0843',
    '#3D2469',
    '#969ED1',
    '#A07733',
    '#F982FF'
]