const translateStatus = (status: number | null) => {
    if (status === null) return 'Sin datos'
    if (status === -1 || status < -1) return 'Retroceso'
    if (status === 0) return 'Sin cambios'
    if (status === 1 || status > 1) return 'Mejora'
    return "";
}

const EvaluationStates = ({ status, evaluationStateStyleText }: { status: number | null, evaluationStateStyleText: string }) => {
    const statusClasses: Record<string, string> = {
        '-1': 'text-indicator-red', //Red to 'Failed'
        '0': 'text-indicator-yellow', // Yellow to 'Not changes'
        '1': 'text-indicator-green' // Green to 'Better',
    };

    //Color for default
    const defaultClass = 'text-gray-60';

    const statusClass = status !== null ? statusClasses[status.toString()] : defaultClass;

    return (
        <span className={`${statusClass} ${evaluationStateStyleText}`}>
            {translateStatus(status)}
        </span>
    )
}

export default EvaluationStates