import Root from "./routes/Root";
import "./FirebaseConfig";
import { HelmetProvider } from "react-helmet-async";
const helmetContext = {};

function App() {
  return (
    <HelmetProvider context={helmetContext}>
      <Root />
    </HelmetProvider>
  );
}

export default App;
