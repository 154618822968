import React from 'react'
import { IMediaAndContent } from '../../../interfaces/MediaAndContent'
import MediaAndContentMapper from '../../../components/Mappers/MediaAndContentMapper'

interface IRelatedContent {
    title: string,
    relatedContent: IMediaAndContent[],
    subPath: string
}

const RelatedContent = ({ title, relatedContent, subPath }: IRelatedContent) => {
    return (
        <div className='flex flex-col gap-6'>
            <h3 className='text-tlarge text-system-light-100'>{title}</h3>
            <MediaAndContentMapper data={relatedContent} viewType='mini' subPath={subPath} />
        </div>
    )
}

export default RelatedContent