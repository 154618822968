import React from 'react'
import '../../styles.css'

interface IPeriod {
    labelPeriod: string,
    onHandleChangePeriod: (e: React.ChangeEvent<HTMLInputElement>) => void;
    disabledPeriod: boolean;
    minPeriodDate: string;
    maxPeriodDate: string;
    value: string
}

const Period = ({ labelPeriod, onHandleChangePeriod, disabledPeriod, minPeriodDate, maxPeriodDate, value }: IPeriod) => {
    return (
        <div className={`${disabledPeriod ? 'opacity-50' : ''} flex flex-col min-w-[150px] text-llarge `}>
            <label className='text-system-light-40 ps-2 pb-1'>{labelPeriod}</label>
            <div className={`h-[32px] px-2 md:py-1 py-[3px] flex items-center rounded-md ${!value ? ' border border-system-light-50 text-system-light-40 ' : 'bg-system-secondary-10 text-secondary-10'}`}>
                <input
                    type='date'
                    className={`period-input w-full  ${!value ? 'text-system-light-40 ' : 'bg-system-secondary-10 text-secondary-10'} `}
                    placeholder="DD/MM/YYYY"
                    onChange={onHandleChangePeriod}
                    disabled={disabledPeriod}
                    min={minPeriodDate}
                    max={maxPeriodDate}
                    value={value}
                />
            </div>
        </div>
    )
}

export default Period
