import { PieChart, Pie, ResponsiveContainer } from 'recharts'
import { PieChartData } from '../../interfaces/Indicator'
import { forwardRef, useEffect, useState } from 'react'
import { colors } from '../../utils/colors'
import ReferencesMapper from '../Mappers/ReferencesMapper'

const PieCharts = forwardRef<HTMLDivElement, { data: PieChartData[] }>(({ data }, ref) => {
    const [chartData, setChartData] = useState<PieChartData[] | null>(null);

    useEffect(() => {
        if (data.length) {
            const formattedData = data.map((entry, index) => ({
                name: entry.name,
                value: entry.value,
                fill: colors[index]
            }));
            setChartData(formattedData);
        }
    }, [data]);

    return (
        <div>
            <ResponsiveContainer width="100%" height={382} ref={ref}>
                <PieChart width={100} height={40} margin={{ top: 52, right: 32, left: 32, bottom: 52 }}>
                    {
                        chartData?.map((entry, index) => (
                            <Pie
                                dataKey="value"
                                isAnimationActive={false}
                                data={chartData}
                                cx="50%"
                                cy="50%"
                                outerRadius={110}
                                fill="#fff"
                                label
                                aria-label={entry.name}
                                key={index}
                            />
                        ))
                    }
                </PieChart>
            </ResponsiveContainer>
            <div className='relative bottom-[50px]'>
                <ReferencesMapper data={chartData ?? []} />
            </div>
        </div>
    );
})

export default PieCharts;
