import React from 'react';
import { IconType } from 'react-icons';
import { Link } from 'react-router-dom';
import Button from '../Buttons/Button';
import ResponsiveContainer from '../../layouts/ResponsiveContainer';

interface CTABoxProps {
    title?: string;
    linkPath: string;
    buttonLabel: string;
    buttonIcon?: IconType;
    background?: string;
}

const CTABox: React.FC<CTABoxProps> = ({
    title = "¿Tenés dudas o consultas?",
    linkPath,
    buttonLabel,
    buttonIcon = undefined,
    background = 'bg-[#D7D9E5]/30'
}) => {
    return (
        <div className="flex flex-col justify-center w-full items-center mt-10">
            <ResponsiveContainer className={`${background} rounded-lg`}>
                <div className='flex flex-col gap-2 py-6 px-8'>
                    <h4 className='text-tlarge text-primary-10'>{title}</h4>
                    <Link to={linkPath}>
                        <Button label={buttonLabel} icon={buttonIcon} as='link' className='text-primary-40' />
                    </Link>
                </div>
            </ResponsiveContainer>
        </div>
    );
}

export default CTABox;