import IndicatorGraphs from './IndicatorGraphs'
import HeaderOfGraphs from './HeaderOfGraphs'
import { AllData, BaseMap, ChartData, Graphics, Variable_indicator } from '../../../../interfaces/Indicator'
import { useEffect, useRef, useState } from 'react'
import useDownloadChart from '../../../../hooks/useDownloadChart'
import exportToExcel from '../../utils/exportToExcel'
import { handleDownloadMap } from '../../utils/exportMapToPNG'

interface IContainerGraphs {
  graphics: Graphics[],
  source: string
  variables_indicator: Variable_indicator[]
  titulo: string
  breadcrumbs: string
  data: AllData[]
}

const ContainerGraphs = ({ graphics, source, variables_indicator, breadcrumbs, data }: IContainerGraphs) => {
  const chartRef = useRef<HTMLDivElement>(null);

  const [chartIndex, setChartIndex] = useState<number>(0);
  const [chart, setChart] = useState<Graphics | null>(null);
  const [dataChart, setDataChart] = useState<ChartData[] | BaseMap[]>([]);
  const [disabledGoBack, setDisabledGoBack] = useState<boolean>(false);
  const [disabledGoForward, setDisabledGoForward] = useState<boolean>(graphics.length === 1);
  const [cleanFilters, setCleanFilters] = useState<boolean>(false);
  //Junta los datos de los filtros para descargarlo con el excel
  const [filtersSelected, setFiltersSelected] = useState<{ [key: string]: string }>({});

  // --> ESTADOS PARA MAPAS<-- //
  //Datos transformados para filtro de mapa
  const [filtersMap, setFiltersMap] = useState<any[]>([]);
  const [dataMaps, setDataMaps] = useState<any[]>([]); //Guarda los datos base de los mapas
  //Errores de información en mapas
  const [errorsNoData, setErrorsNoData] = useState<boolean>(false);


  const TYPE_OF_INDICATOR: string = breadcrumbs.split('>').map(part => part.trim())[1] || '';

  useEffect(() => {
    if (graphics.length > 0 && chartIndex < graphics.length) {
      const currentGraph = graphics[chartIndex];
      setChart(currentGraph);

      if (currentGraph.data_formated) {
        // Filtrar los datos eliminando los que tengan null
        const filteredData = currentGraph.data_formated.filter((item: any) =>
          Object.values(item).some(value => value !== null)
        );
        setDataChart(filteredData);
      } else if (currentGraph.maps) {
        setDataChart(currentGraph.maps);
        setDataMaps(currentGraph.maps);
        // Realizar la transformación de datos de años
        const transformed = [
          {
            id: 1,
            titulo: "Año",
            values: Array.from(
              new Set(
                currentGraph.maps.map((map: any) => map.Anio)
              )
            )
          }
        ];
        setFiltersMap(transformed);
      }
    }
  }, [chartIndex, graphics, TYPE_OF_INDICATOR]);

  useEffect(() => {
    setDisabledGoBack(chartIndex === 0);
    setDisabledGoForward(chartIndex === graphics.length - 1);
  }, [chartIndex, graphics.length]);

  /* INICIO DESCARGA DE DATOS */
  const { downloadChart } = useDownloadChart(chartRef, chart!, source, TYPE_OF_INDICATOR, filtersSelected);

  //Opciones para la descarga
  const optionsDropdownDowload = [
    { label: 'Imagen en .png', action: !chart?.maps ? () => downloadChart('png') : () => handleDownloadMap(chartRef, chart?.titulo!, 'png') },
    { label: 'Imagen en .jpg', action: !chart?.maps ? () => downloadChart('jpg') : () => handleDownloadMap(chartRef, chart?.titulo!, 'jpg') },
    { label: 'Datos en Excel', action: () => exportToExcel(dataChart!, source, chart?.titulo!, filtersSelected, chart?.nota_grafico!) },
  ];
  /* FIN DESCARGA DE DATOS */

  /* INICIO FUNCIONES DE MOVIMIENTO */
  const goBack = () => {
    if (chartIndex > 0) {
      setChartIndex(prevIndex => prevIndex - 1);
      setCleanFilters(true)
    }
  }

  const goForward = () => {
    if (chartIndex < graphics.length - 1) {
      setChartIndex(prevIndex => prevIndex + 1);
      setCleanFilters(true)
    }
  }
  /* FIN FUNCIONES DE MOVIMIENTO */

  /* START HANDLE CLEAN FILTERS */
  //Es usado para limpiar los filtros y reestablecer la información
  const handleCleanFilters = () => {
    setDataChart(chart && chart.data_formated ? chart.data_formated : [])
    setCleanFilters(true)
  }
  /* END HANDLE CLEAN FILTERS */

  if (!chart) {
    return null
  }

  return (
    <div className='shadow shadow-gray-0/30 rounded-md'>
      <HeaderOfGraphs
        title={chart ? chart.titulo : ''}
        goBack={goBack}
        goForward={goForward}
        disabledGoBack={disabledGoBack}
        disabledGoForward={disabledGoForward}
        optionsDropdownDowload={optionsDropdownDowload}
        variables_indicador={!chart.maps?.length ? variables_indicator : filtersMap} //Si es de tipo mapa que se muestren los filtros de años
        typeOfIndicador={TYPE_OF_INDICATOR}
        data={!chart.maps?.length ? data : dataMaps}
        setDataChart={setDataChart}
        variable_analisis={chart && chart.variable_analisis ? chart?.variable_analisis : []}
        cleanFilters={cleanFilters}
        setCleanFilters={setCleanFilters}
        setFiltersSelected={setFiltersSelected}
        isPercentageChart={chart && chart.porcentual ? chart.porcentual : null}
        handleCleanFilters={handleCleanFilters}
        filters={chart?.filtros}
        typeOfGraph={chart?.type_of_graph}
        setErrorNoData={setErrorsNoData}
      />
      <div className='pb-8'>
        <IndicatorGraphs
          typeOfGraph={chart?.type_of_graph}
          data={chart ? dataChart : []}
          chartRef={chartRef}
          typeOfIndicator={TYPE_OF_INDICATOR}
          variable_analisis={chart && chart.variable_analisis ? chart?.variable_analisis : []}
          domain={{
            maximo: chart?.maximo ?? 0,
            minimo: chart?.minimo ?? 0,
            intervalo: chart?.intervalo ?? 1,
            ticks: chart?.ticks ?? [],
          }}
          errorsNoData={errorsNoData}
        />
        {
          chart.nota_grafico &&
          <p className='px-8 text-bsmall text-system-light-40'>Nota: {chart.nota_grafico}</p>
        }
      </div>
    </div>
  )
}

export default ContainerGraphs


