import React from 'react'
import { MdAccessTime } from 'react-icons/md';

const DateWithIcon = ({ date }: { date: string }) => {
    const originalDate = new Date(date).toLocaleDateString('es-ES', { day: '2-digit', month: 'long', year: 'numeric' });
    return (
        <div className='flex items-center gap-2'>
            <MdAccessTime />
            <span className='text-bmedium text-system-light-40'>{originalDate}</span>
        </div>
    )
}

export default DateWithIcon