import { ChartData, Filtro } from "../../../../interfaces/Indicator";
import { AllData, Variable_indicator } from "../../../../interfaces/Indicator";
import { INDICATOR_TYPE_RESULTS } from "../../../../utils/const";
import FilterOfGraphs from "./FilterOfGraphs";
import NavigationHeader from "./NavigationHeader";
import { FiDownload } from "react-icons/fi";
import ButtonDropdown from "../../../../components/Dropdown/ButtonDropdown";

export interface IHeaderOfGraphs {
    title: string,
    goForward: () => void;
    goBack: () => void;
    disabledGoBack: boolean;
    disabledGoForward: boolean;
    variables_indicador: Variable_indicator[],
    typeOfIndicador: string,
    data: AllData[],
    setDataChart: React.Dispatch<React.SetStateAction<ChartData[]>> | any
    variable_analisis: string[]
    cleanFilters: boolean
    setCleanFilters: React.Dispatch<React.SetStateAction<boolean>>
    optionsDropdownDowload: { label: string; action: () => void; }[]
    setFiltersSelected: React.Dispatch<React.SetStateAction<{
        [key: string]: string;
    }>>
    isPercentageChart: null | 1 | 0,
    handleCleanFilters: () => void;
    filters: Filtro[]
    typeOfGraph: 1 | 2 | 3 | 4 | 5 | 6 | number;
    setErrorNoData: React.Dispatch<React.SetStateAction<boolean>>
}

const HeaderOfGraphs = ({
    title,
    goForward,
    goBack,
    disabledGoBack,
    disabledGoForward,
    variables_indicador,
    typeOfIndicador,
    data,
    setDataChart,
    variable_analisis,
    cleanFilters,
    setCleanFilters,
    optionsDropdownDowload,
    setFiltersSelected,
    isPercentageChart,
    handleCleanFilters,
    filters,
    typeOfGraph,
    setErrorNoData
}: IHeaderOfGraphs) => {

    return (
        <div>
            <NavigationHeader
                title={title}
                goBack={goBack}
                goForward={goForward}
                disabledGoBack={disabledGoBack}
                disabledGoForward={disabledGoForward}
            />
            <div className={`flex ${typeOfIndicador === INDICATOR_TYPE_RESULTS ? 'justify-between' : 'justify-end'} items-end px-8 py-3 bg-[#F0F4F8]`}>
                <FilterOfGraphs
                    typeOfIndicator={typeOfIndicador}
                    variables_indicador={variables_indicador}
                    data={data}
                    setDataChart={setDataChart}
                    variable_analisis={variable_analisis}
                    cleanFilters={cleanFilters}
                    setCleanFilters={setCleanFilters}
                    setFiltersSelected={setFiltersSelected}
                    isPercentageChart={isPercentageChart}
                    handleCleanFilters={handleCleanFilters}
                    filters={filters}
                    typeOfGraph={typeOfGraph}
                    setErrorNoData={setErrorNoData}
                />
                <ButtonDropdown
                    options={optionsDropdownDowload}
                    buttonContent={<FiDownload size={18} />}
                />
            </div>
        </div>
    )
}

export default HeaderOfGraphs