import React from 'react'
import { hexToRgb } from '../../utils/utils'

const Chips = ({ label, color }: { label: string, color: string }) => {
    const colorText = `rgb(${hexToRgb(color)})`
    const colorBg = `rgb(${hexToRgb(color)}, 0.1)`

    return (
        <div className="rounded-md w-max px-4 py-px" style={{ backgroundColor: colorBg }}>
            <span className="text-lmedium " style={{ color: colorText }}>{label}</span>
        </div>
    )
}

export default Chips