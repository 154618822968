import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import seg from "../../utils/seg.json";
import L from "leaflet";
import ResponsiveContainer from '../../layouts/ResponsiveContainer';
import icon from '../../assets/icons/openmoji_location-indicator.png';
import { BaseMap } from '../../interfaces/Indicator';
import { useEffect, useState } from 'react';
import NotDataMap from '../Cards/NotDataMap';


// Interfaces
interface geoJSON {
    type: string;
    features: any;
}

// Utilidades y constantes
const COLOR = '#0E6681';

const convertToGeoJSON = (data: BaseMap[]) => {
    return {
        "type": "FeatureCollection",
        "features": data.map((item) => {
            const { Coordenadas, ...restProperties } = item;
            return {
                "type": "Feature",
                "properties": restProperties,
                "geometry": {
                    "type": "Point",
                    "coordinates": Coordenadas
                }
            };
        })
    };
};

const setColor = () => ({
    color: COLOR,
    weight: 3,
    opacity: 0.90
});

// Componente para los marcadores
const MarkersOnMap = () => {
    const map = useMap(); // Hook para acceder al objeto mapa

    const onEachFeature = (feature: any, layer: any) => {
        if (feature.properties && feature.properties.COMUNAS) {
            const comunaNumber = feature.properties.COMUNAS;
            const center = (layer as L.GeoJSON).getBounds().getCenter(); // Centro del polígono

            // Añade un marcador en el centro del polígono
            L.marker(center, {
                icon: L.divIcon({
                    className: 'comuna-label',
                    html: `<div style="background: none; padding: 2px 5px; font-size: 24px; font-weight: 600; color: ${COLOR}">${comunaNumber}</div>`
                })
            }).addTo(map);
        }
    };

    return <GeoJSON data={seg as any} style={setColor} onEachFeature={onEachFeature} />;
};

// Componente para la capa GeoJSON con puntos
const GeoJSONLayer = ({ data }: { data: geoJSON }) => {

    const defaultIcon = L.icon({
        iconUrl: icon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
        popupAnchor: [1, -34],
        shadowUrl: 'https://unpkg.com/leaflet@1.7.1/dist/images/marker-shadow.png',
        shadowSize: [41, 41]
    });

    const pointToLayer = (feature: any, latlng: L.LatLng) => {
        const marker = L.marker(latlng, { icon: defaultIcon });

        if (feature.properties) {
            const { Coordenadas, Anio,  ...restProperties } = feature.properties;
            let popupContent = "<p>";
            for (const [key, value] of Object.entries(restProperties)) {
                popupContent += `<b>${key}</b>: ${value}<br />`;
            }
            popupContent += "</p>";
            marker.bindPopup(popupContent);
        }

        return marker;
    };

    return <GeoJSON data={data as any} pointToLayer={pointToLayer} />;
};

// Componente principal
const CustomMap = ({ data, mapRef, errorsNoData }: { data: BaseMap[], mapRef: React.RefObject<HTMLDivElement>, errorsNoData: boolean }) => {

    const [dataFormated, setDataFormated] = useState<geoJSON>();

    useEffect(() => {
        if (data?.length > 0) {
            const geoJSON = convertToGeoJSON(data);
            setDataFormated(geoJSON);
        }
    }, [data]);

    return (
        <ResponsiveContainer>
            <div style={{ position: "relative", height: "600px", marginBottom: '24px' }}>
                <div ref={mapRef} id="my-node" style={{ width: '100%', height: '100%', position: 'relative' }}>
                    <MapContainer
                        style={{ width: "100%", height: "100%" }}
                        center={[-34.619948411233096, -58.44110846308608]}
                        zoom={11.5}
                        scrollWheelZoom={false}
                        preferCanvas={true}
                    >
                        <TileLayer
                            attribution='&copy; <a href="https://openmaptiles.org/">OpenMapTiles</a> &copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
                            url='https://tile.openstreetmap.org/{z}/{x}/{y}.png'
                        />
                        {/* Marcadores y GeoJSON */}
                        <MarkersOnMap />
                        {dataFormated && <GeoJSONLayer data={dataFormated} key={JSON.stringify(dataFormated)} />}
                    </MapContainer>
                    {errorsNoData ? <NotDataMap /> : null}
                </div>
            </div>
        </ResponsiveContainer>
    );
};

export default CustomMap;
