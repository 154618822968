import AccessToRigthsMapper from "../../../components/Mappers/AccessToRigthsMapper"
import Titles from "../../../components/Titles/Titles"
import { EvaluatedRights } from "../../../interfaces/Rights"
import ResponsiveContainer from "../../../layouts/ResponsiveContainer"
import Dropdown from '../../../components/Inputs/Selects/CustomSelect';
import { Period } from "../../../interfaces/Periods";
import Loading from "../../../components/Loading/Loading";

interface IQuickAccessToRights {
    quickAccessToRights: EvaluatedRights[],
    // dateOfEvaluation: string,
    periods: Period[],
    lastPeriod: string,
    handleOnChangePeriods: (period: string) => void,
    selectedPeriod: string,
    loading: "idle" | "pending" | "succeeded" | "failed"
}

const QuickAccessToRights = ({ quickAccessToRights, periods, lastPeriod, handleOnChangePeriods, selectedPeriod, loading }: IQuickAccessToRights) => {

    return (
        <div className="flex flex-col justify-center w-full items-center">
            {/* Encabezado */}
            <ResponsiveContainer className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
                <Titles title={"Derechos"} />
                <div className="flex items-end gap-3">
                    <Dropdown
                        options={periods}
                        onSelect={handleOnChangePeriods}
                        selectedItem={lastPeriod}
                        defaultSelectedItem={lastPeriod}
                        label='Período'
                        bgSelect='bg-primary-100'
                        className='min-w-[150px] max-w-[150px]'
                    />
                    {
                        loading === 'pending'
                            ? <Loading size={26} color="rgb(14 102 129)" />
                            : null
                    }
                </div>
            </ResponsiveContainer>
            <AccessToRigthsMapper
                quickAccessToRights={quickAccessToRights}
                initialSelectedPeriod={selectedPeriod}
            />
        </div>
    )
}

export default QuickAccessToRights