import LawCard from '../Cards/LawCard';
import Button from '../Buttons/Button';

const LawList = ({
    laws,
    showAll,
    toggleShowAll,
}: {
    laws: Array<{ nombre: string; texto: string; pdf: string }>;
    showAll: boolean;
    toggleShowAll: () => void;
}) => (
    <div className="flex flex-col items-center gap-4">
        {laws.slice(0, showAll ? laws.length : 3).map((law, index) => (
            <LawCard key={index} name={law.nombre} text={law.texto} fileName={law.pdf} />
        ))}
        {laws.length > 3 && (
            <Button
                label={showAll ? 'Ver menos' : 'Ver más'}
                onClick={toggleShowAll}
                className="bg-primary-40 text-primary-100 mt-4"
            />
        )}
    </div>
);

export default LawList