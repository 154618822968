import Hero from "../../components/Hero/Hero"
import QuickAccessToRights from "./components/QuickAccessToRights"
import FeaturedActivities from "./components/FeaturedActivities"
import Breadcrumbs from "../../components/Breadcrumbs/Breadcrumbs"
import { useAppDispatch, useAppSelector } from "../../redux/hooks"
import { useEffect, useState } from "react"
import { getActivities } from "../../redux/slices/activities/activitiesThunk"
import { getVideo } from "../../redux/slices/monitor/monitorThunk"
import { getRights } from "../../redux/slices/rights/rightsThunk"
import SEO from "../../components/SEO/SEO"
import DynamicBannerCard from "../../components/Cards/DynamicBannerCard"
import file from '../../assets/icons/icon-file.svg'
import Titles from "../../components/Titles/Titles"
import ResponsiveContainer from "../../layouts/ResponsiveContainer"
import CTABox from "../../components/Cards/CTABox"
import { MdArrowForward } from "react-icons/md"
import { getPeriods } from "../../redux/slices/periods/periodsThunk"
import { setSelectedPeriod } from "../../redux/slices/periods/periodsSlice"
import Warning from "../../components/Warning/Warning"
import { useAutoModal } from "../../hooks/useAutoModal"

const Index = () => {
    const dispatch = useAppDispatch();
    const activities = useAppSelector(state => state?.activities.majorActivities);
    const video = useAppSelector((state) => state.monitor.video);
    const { rights, loading } = useAppSelector((state) => state.rights);
    const { periods, lastPeriod } = useAppSelector((state) => state.periods)

    const [isModalOpen, setIsModalOpen] = useState(true);

    useEffect(() => {
        const fetchPeriodsAndVideo = async () => {
            try {
                if (!video && !periods) {
                    await Promise.all([
                        dispatch(getVideo()),
                        dispatch(getPeriods()),
                    ]);
                }
            } catch (error) {
                console.error('Error fetching video or periods:', error);
            }
        };
        fetchPeriodsAndVideo();
    }, [video, periods, dispatch]);

    useEffect(() => {
        if (!rights && lastPeriod && lastPeriod.length > 0) {
            dispatch(getRights(lastPeriod));
        }
    }, [rights, lastPeriod, dispatch]);

    useEffect(() => {
        try {
            if (!activities) {
                dispatch(getActivities({ page: 1 }))
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }, [activities, dispatch])

    const handlePeriods = (period: string) => {
        if (!period) return
        const year = period.split(" ").pop() || '';
        dispatch(setSelectedPeriod({ period: year }))
        dispatch(getRights(year))
    }

    return (
        <>
            <SEO
                title='Monitor de derechos CABA'
                description="Monitor de derechos de la ciudad de Buenos Aires"
                type='website'
            />
            <Hero
                breadcrumbs={<Breadcrumbs />}
                title={"Monitor de Derechos"}
                paragraph="¡Te damos la bienvenida al Monitor de Derechos de la Defensoría del Pueblo de la Ciudad de Buenos Aires! Esta plataforma evalúa la situación de diversos derechos en la ciudad, para fortalecer su protección. A través del Semáforo, podrás identificar mejoras o retrocesos en áreas como educación, salud, vivienda y trabajo. El Visualizador de Indicadores te ofrece datos gráficos y detallados para explorar tendencias desde una perspectiva inclusiva. En el espacio de Investigaciones y Acciones, encontrás estudios del Monitor que abordan estos temas, brindando una visión integral de la realidad. ¿Querés saber más? ¡Mirá nuestro video explicativo!"
                media={{ type: 'video', src: '', url: `${video?.link}`, alt: `${video?.titulo}`, className: '' }}
                withShape={true}
                layout="row"
                contentContainerClassName='xl:items-end flex-col xl:flex-row'
            />
            <div className="pt-16 xl:pt-32 "></div>
            {
                rights && rights.length > 0
                    ?
                    <>
                        <QuickAccessToRights
                            quickAccessToRights={rights ? rights : []}
                            periods={periods ?? []}
                            lastPeriod={`Período ${lastPeriod}`}
                            handleOnChangePeriods={handlePeriods}
                            selectedPeriod={lastPeriod || ''}
                            loading={loading}
                        ></QuickAccessToRights>
                        {/* Advertencia de datos faltantes */}
                        <div className="flex flex-col justify-center w-full items-center mt-6">
                            <ResponsiveContainer>
                                <Warning
                                    isOpen={isModalOpen}
                                    setIsOpen={setIsModalOpen}
                                    position="relative"
                                />
                            </ResponsiveContainer>
                        </div>
                    </>
                    : null
            }
            <div className="flex flex-col justify-center w-full items-center mt-16">
                <ResponsiveContainer className='flex flex-col gap-6'>
                    <Titles title='Investigaciones y Acciones' />
                    <DynamicBannerCard
                        text='Conocé cómo promovemos, protegemos y defendemos tus derechos.'
                        icon={file}
                        buttonLabel='Ver'
                        buttonUrl='investigaciones-y-acciones'
                        colorText='text-primary-100'
                        bgColor="#042936"
                        colorPolygon='#C0C8CC'
                        classNameButton='bg-system-secondary-10 text-primary-0'
                        colorTitle=''
                        title=''
                    />
                </ResponsiveContainer>
            </div>
            {
                activities && activities?.length > 0
                    ? <FeaturedActivities activities={activities} />
                    : <></>
            }
            <CTABox
                title="¿Tenés dudas o consultas?"
                linkPath="/monitor/contactanos"
                buttonLabel="Contactanos"
                buttonIcon={MdArrowForward}
                background="bg-[#D7D9E5]/30 "
            />

        </>
    )
}

export default Index 