import React from 'react'

// Define la interfaz para las columnas
interface Column<T> {
    label: string;
    accessor: keyof T;
    render?: (value: T[keyof T], row: T) => React.ReactNode;
}

// Define la interfaz para las props del componente
interface TableProps<T> {
    columns: Column<T>[];
    data: T[];
    bgColorTr?: string;
    colorTr?: string
}

const CustomTable = <T,>({ columns, data, bgColorTr = '#FFFFF', colorTr = 'text-primary-10' }: TableProps<T>) => {

    return (
        <div className="overflow-x-auto">
            <table className="min-w-full bg-white border rounded-xl border-system-primary-20">
                <thead className="bg-gray-200">
                    <tr className={`${bgColorTr} ${colorTr}`}>
                        {columns.map((column, colIndex) => (
                            <th
                                key={String(column.accessor)}
                                className={`p-4 border-b border-system-primary-20 text-llarge text-gray-600 ${colIndex === 0 ? 'text-left' : 'text-center'}`}
                            >
                                {column.label.toLocaleUpperCase()}
                            </th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row, rowIndex) => (
                        <tr key={rowIndex} className="">
                            {columns.map((column, colIndex) => (
                                <td
                                    key={String(column.accessor)}
                                    className={`py-2 px-4 border-b border-system-primary-20 text-gray-800  ${colIndex === 0 ? 'w-2/3 text-left' : 'text-center'} `}                                >
                                    {column.render
                                        ? column.render(row[column.accessor], row)
                                        : String(row[column.accessor])}
                                </td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </div >
    );
}

export default CustomTable