import { useEffect, useState } from 'react'
import { Evaluaciones, Subdimensiones } from '../../../interfaces/Evaluations'
import DynamicBannerCard from '../../../components/Cards/DynamicBannerCard'
import dashboard from '../../../assets/icons/dashboard.svg';
import Timeline from '../../../components/Timeline/Timeline';
import FixedTable from '../../../components/Tables/FixedTable';
import InstitucionalDesing from './InstitucionalDesing';
import { useAppSelector } from '../../../redux/hooks';

const EvaluationSubdimensionStructural = ({ evaluation_subDimension }: { evaluation_subDimension: Subdimensiones[] }) => {
    const [rightId, setRightId] = useState('');
    const [period, setPeriod] = useState('');
    const [right, setRight] = useState('');

    const { selectedRight } = useAppSelector((state) => state.indicator_display_filter)

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const params = new URLSearchParams(window.location.search);
            setRight(params.get('right') || '');
            setRightId(params.get('rightId') || '');
            setPeriod(params.get('periodo') || '');
        }
    }, []);

    const combinedEvaluations = evaluation_subDimension
        .map(eva => eva.evaluaciones)
        .filter((evaluaciones): evaluaciones is Evaluaciones => evaluaciones !== undefined)
        .reduce((acc, current) => {
            Object.keys(current).forEach(year => {
                acc[year] = acc[year] ? [...acc[year], ...current[year]] : current[year];
            });
            return acc;
        }, {} as Evaluaciones);

    // Eliminar normas repetidas por año
    const uniqueEvaluations = Object.keys(combinedEvaluations).reduce((acc, year) => {
        const uniqueNorms = new Map<number, typeof combinedEvaluations[typeof year][number]>();
        combinedEvaluations[year].forEach((evaluation) => {
            if (!uniqueNorms.has(evaluation.indicador_estructural_id)) {
                uniqueNorms.set(evaluation.indicador_estructural_id, evaluation);
            }
        });
        acc[year] = Array.from(uniqueNorms.values());
        return acc;
    }, {} as Evaluaciones);

    // Ordenar los años de mayor a menor
    const orderedEvaluations = Object.keys(uniqueEvaluations)
        .sort((a, b) => parseInt(b) - parseInt(a))
        .reduce((acc, year) => {
            acc[year] = uniqueEvaluations[year];
            return acc;
        }, {} as Evaluaciones);

    return (
        <>
            <div className='text-tlarge my-6'>Diagnóstico de compromisos asumidos</div>
            <FixedTable
                evaluation_subDimension={evaluation_subDimension}
                period={period}
            />
            <div className='text-tlarge mt-10 mb-6'>Leyes a lo largo del tiempo</div>
            <Timeline
                laws={orderedEvaluations}
                period={period}
            />
            <div className='mt-10'></div>
            <InstitucionalDesing institucional={selectedRight?.texto_modal ?? ''} />
            <div className='mt-10'></div>
            <DynamicBannerCard
                colorTitle='text-primary-10'
                title='Metodología de evaluación'
                text='Conocé los criterios y los pasos que seguimos para realizar la evaluación del derecho y sus dimensiones'
                colorText='system-light-40'
                icon={dashboard}
                buttonLabel='Ver'
                buttonUrl={`/monitor/metodologia?right=${right}&rightId=${rightId}`}
                bgColor="#DCE4E9"
                colorPolygon='#001F29'
                classNameButton='bg-primary-40 text-primary-100'
            />
        </>
    )
}

export default EvaluationSubdimensionStructural