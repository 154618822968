import React from 'react'
import { MdOutlineClose } from "react-icons/md";

const Warning = ({ isOpen, setIsOpen, position = 'absolute' }: { isOpen: boolean, setIsOpen: React.Dispatch<React.SetStateAction<boolean>>, position?: 'absolute' | 'relative' }) => {

  if (!isOpen) return null

  return (
    <div className='flex justify-center relative'>
      <div className={`bg-[#2C3134] text-[#EDF1F5] ${position} z-[9379289] flex items-center gap-12 p-3 rounded-md shadow-md shadow-gray-0/20`}>
        <p className='text-bmedium '> <span className='text-blarge font-bold '>¡Atención! </span>Como se advierte en cada caso, algunos indicadores no fueron evaluados en 2023 porque se encuentra pendiente la publicación de información oficial o la respuesta de las autoridades correspondientes. Cuando se disponga de estos datos se procederá a su actualización. Te invitamos a navegar por el Monitor.</p>
        <button onClick={() => setIsOpen(!isOpen)} className="flex  justify-end">
          <MdOutlineClose size={24} />
        </button>
      </div>
    </div>
  )
}

export default Warning