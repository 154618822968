// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comuna-label {
    cursor: default !important; /* Forzar cursor predeterminado */
}



.leaflet-pane .leaflet-iterative {
    cursor: default !important; /* Asegura que todo el mapa tenga cursor por defecto */
}
`, "",{"version":3,"sources":["webpack://./src/components/Maps/styles.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B,EAAE,iCAAiC;AACjE;;;;AAIA;IACI,0BAA0B,EAAE,sDAAsD;AACtF","sourcesContent":[".comuna-label {\n    cursor: default !important; /* Forzar cursor predeterminado */\n}\n\n\n\n.leaflet-pane .leaflet-iterative {\n    cursor: default !important; /* Asegura que todo el mapa tenga cursor por defecto */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
