import { MdGavel, MdOutlineDescription } from "react-icons/md";
import DownloadPDFWithFetch from "../DownloadPDF/DownloadPDFWithFetch";

interface ILawCard {
    name: string,
    text: string,
    fileName: string,
}

const LawCard = ({ name, text, fileName }: ILawCard) => {
    return (
        <div className="rounded-md bg-system-light-10 w-full">
            <div className="flex items-center justify-between px-6 py-2">
                <div className="flex items-center gap-2">
                    <MdGavel size={24} />
                    <div className='p-3'>
                        <h4 className='text-blarge'>{name}</h4>
                        <p className='text-bmedium'>{text}</p>
                    </div>
                </div>
                {
                    fileName &&
                    <DownloadPDFWithFetch
                        fileName={fileName}
                        label=""
                        icon={MdOutlineDescription}
                        iconSize={24}
                    />
                }
            </div>
        </div>
    )
}

export default LawCard