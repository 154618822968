import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
import { IMediaAndContent } from "../../../interfaces/MediaAndContent";
const { REACT_APP_API_PATH } = process.env;

//Intefaces de los gets
interface getDetailAndRelatedResearchsResponse {
    detail: IMediaAndContent;
    related: IMediaAndContent[];
}

interface ErrorResponse {
    message: string;
    // otros campos relevantes del error
}

export const getDetailAndRelatedMethodologies = createAsyncThunk<getDetailAndRelatedResearchsResponse, string, { rejectValue: ErrorResponse }>(
    "methodologies/ByDerechoId",
    async (id: string, thunkAPI) => {
        try {
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/monitor/methodologies/ByDerechoId?id=${id}`)
            const data = response.data
            return {
                detail: data.data.activity,
                related: data.data.related
            }
        } catch (error: any) {
            return {
                detail: [],
                related: null,
            }
        }
    }
);
