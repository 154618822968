import React from 'react'
import Accordion from '../../../components/Accordion/Accordion'
import ResponsiveContainer from '../../../layouts/ResponsiveContainer'

const FAQS = () => {
    return (
        <div className='flex flex-col items-center'>
            <ResponsiveContainer className='flex flex-col gap justify-center' >
                <h2 className='text-hmedium md:text-dsmall text-primary-10 mt-20'>Preguntas frecuentes</h2>
                <div className='mt-8 flex flex-col gap-4'>
                    <Accordion
                        title='¿Qué derechos monitorea el sistema?'
                        answer='El Monitor se propone visibilizar y evaluar los avances y retrocesos en la situación de derechos económicos, sociales, culturales y ambientales, dentro de la Ciudad Autónoma de Buenos Aires. En consonancia con los estándares de los sistemas internacional e interamericano, en una primera etapa priorizó el diagnóstico y seguimiento del derecho a la educación, la salud, la vivienda y el trabajo.'
                        isOpen={true}
                    />
                    <Accordion
                        title='¿Cómo se recopilan los datos y quién los analiza?'
                        answer={`El Monitor de Derechos cuenta con un equipo de trabajo interdisciplinario que, con un enfoque colaborativo, tiene a su cargo la recopilación y el análisis de datos que, en su mayoría, son oficiales. En concreto, releva, verifica y organiza información de diversas fuentes de carácter local y nacional. 
                        Luego, esa información se traslada al visualizador de indicadores y - a través del uso de una metodología adecuada y herramientas tecnológicas - se procede a la evaluación de cada indicador, subdimensión, dimensión, tipo de indicador y derecho. 
                        Garantizando la transparencia del proceso, es posible identificar avances y retrocesos en el cumplimiento del derecho, señalar mejoras, identificar vulneraciones sistemáticas y proponer transformaciones que contribuyan a superarlas.`}
                        isOpen={false}
                    />
                    <Accordion
                        title='¿Qué acciones se llevan adelante con la información producida por el monitor?'
                        answer='La información producida por el Monitor de Derechos es un insumo valioso para la elaboración e implementación de las políticas de promoción y protección que lleva adelante la Defensoría del Pueblo de la Ciudad Autónoma de Buenos Aires. En primer lugar, los datos obtenidos permiten sensibilizar y alertar a la comunidad sobre la situación general de cada derecho. Además, nutren diagnósticos que son presentados ante las autoridades competentes en aras de impulsar cambios positivos en los marcos normativos, planes, programas y recursos asignados a su ejecución. La Defensoría del Pueblo también puede emplear esta información en respaldo de acciones de incidencia directa sobre casos particulares. Asimismo, la retroalimentación continua del Monitor de Derechos contribuye a la adaptación y refinamiento de las estrategias y enfoques asumidos para la defensa de los derechos en el distrito.'
                        isOpen={false}
                    />
                </div>

            </ResponsiveContainer>
        </div >
    )
}

export default FAQS