// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.scrollbar-hide {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

.scrollbar-hide::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}`, "",{"version":3,"sources":["webpack://./src/components/Tabs/styles.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB,EAAE,gBAAgB;IAC1C,qBAAqB,EAAE,YAAY;AACvC;;AAEA;IACI,aAAa,EAAE,0BAA0B;AAC7C","sourcesContent":[".scrollbar-hide {\n    -ms-overflow-style: none; /* IE and Edge */\n    scrollbar-width: none; /* Firefox */\n}\n\n.scrollbar-hide::-webkit-scrollbar {\n    display: none; /* Chrome, Safari, Opera */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
