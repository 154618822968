import React from 'react';
import { PDFDocument } from 'pdf-lib';
import Button from '../Buttons/Button';
import { MdOutlineFileDownload } from 'react-icons/md';

interface Props {
    base64String: string;
    name: string;
    label?: string
    className?: string
}

const DownloadPDF: React.FC<Props> = ({ base64String, name, label = 'Descargar', className}) => {
    const handleDownload = async () => {
        try {
            const bytes = Uint8Array.from(atob(base64String), c => c.charCodeAt(0));

            const pdfDoc = await PDFDocument.load(bytes);

            const pdfBytes = await pdfDoc.save();

            const url = URL.createObjectURL(new Blob([pdfBytes], { type: 'application/pdf' }));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', `${name}`);

            document.body.appendChild(link);
            link.click();

            URL.revokeObjectURL(url);
            document.body.removeChild(link);
        } catch (error) {
            console.error('Error al descargar el archivo PDF:', error);
        }
    };

    return (
        <Button
            onClick={handleDownload}
            label={label}
            as='link'
            icon={MdOutlineFileDownload}
            showIconLeft={true}
            showIconRight={false}
            className={`${className}`}
        />
    );
};

export default DownloadPDF;