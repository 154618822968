import { useState, useRef, useEffect, ReactNode } from 'react';

interface DropdownOption {
    label: string;
    action: () => void; // La acción a ejecutar cuando se selecciona la opción
}

interface ButtonDropdownProps {
    options: DropdownOption[];
    buttonContent: ReactNode; // Puede ser un ícono o texto
}

function ButtonDropdown({ options, buttonContent }: ButtonDropdownProps) {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const handleButtonClick = () => {
        setIsOpen(!isOpen);
    };

    const handleOptionClick = (action: () => void) => {
        action();
        setIsOpen(false);
    };

    return (
        <div className="relative" ref={dropdownRef}>
            <button onClick={handleButtonClick} className="flex items-center justify-center border rounded-md border-system-light-50 p-2 hover:brightness-[0.90] hover:shadow hover:shadow-gray-0/30">
                {buttonContent}
            </button>
            {isOpen && (
                <ul className="absolute right-0 w-[200px] rounded-lg bg-[#EAEEF2] shadow-sm shadow-gray-0/30 z-[9999]">
                    {options.map((option, index) => (
                        <li key={index} onClick={() => handleOptionClick(option.action)} className='cursor-pointer text-llarge py-2 px-3 hover:bg-gray-80'>
                            {option.label}
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
}

export default ButtonDropdown;