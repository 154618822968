import React from 'react';
import { Link } from 'react-router-dom';
import MediaAndContentLarge from '../Cards/MediaAndContentLarge';
import MediaAndContentMini from '../Cards/MediaAndContentMini';
import { IMediaAndContent } from '../../interfaces/MediaAndContent';

interface IMediaAndContentMapper {
    data: IMediaAndContent[] | null
    viewType: 'mini' | 'large'
}

const MediaAndContentMapperResearch = ({ data, viewType }: IMediaAndContentMapper) => {
    const renderContent = (renderContent: IMediaAndContent) => {
        const renderContentKey = renderContent.titulo.replaceAll(" ", '-').replaceAll(':', '').toLowerCase();
        // Generamos la URL para el enlace dependiendo del tipo de visualización
        const renderContentLink = viewType === 'large' ?
            `${encodeURIComponent(renderContentKey)}-id${renderContent.id}` :  // URL para la vista grande
            `/monitor/investigaciones-y-acciones/${encodeURIComponent(renderContentKey)}-id${renderContent.id}`; // URL para la vista mini

        return (
            <div className={viewType === 'large' ? 'mb-12' : 'flex gap-6'} key={renderContent.id}>
                <Link to={renderContentLink} state={{ id: `${Number(renderContent.id)}` }}>
                    {viewType === 'large' ? (
                        // Si la vista es grande, renderizamos MediaAndContentLarge
                        <MediaAndContentLarge
                            key={renderContent.id}
                            id={renderContent.id}
                            imagen={renderContent.imagen}
                            imagen_base64={renderContent.imagen_base64}
                            titulo={renderContent.titulo}
                            categorias={renderContent.categorias}
                            descripcion={renderContent.descripcion}
                            fecha={renderContent.fecha}
                        />
                    ) : (
                        // Si la vista es mini, renderizamos MediaAndContentMini
                        <MediaAndContentMini
                            key={renderContent.id}
                            id={renderContent.id}
                            imagen={renderContent.imagen}
                            imagen_base64={renderContent.imagen_base64}
                            titulo={renderContent.titulo}
                            descripcion={renderContent.descripcion}
                            fecha={renderContent.fecha}
                            categorias={renderContent.categorias}
                        />
                    )}
                </Link>
            </div>
        );
    };

    return (
        <div className={viewType === 'large' ? 'mt-12' : 'lg:max-xl:w-[55rem] xl:w:[70.5rem] overflow-x-scroll xl:overflow-hidden pb-4'}>
            {viewType === 'large' ? (
                data?.map(renderContent)
            ) : (
                <div className='flex gap-6'>
                    {data?.map(renderContent)}
                </div>
            )}
        </div>
    );
};

export default MediaAndContentMapperResearch;
// Componente para mapear una lista de datos a diferentes tipos de visualización, por ejemplo las cards de actividades e informes y acciones