import { useState } from 'react'

type Props = {
    copyText: string
}

const ClipboardCopy = ({ copyText }: Props) => {
    const [isCopied, setIsCopied] = useState<boolean>(false)

    const handleCopy = async (content: string) => {
        try {
            await navigator.clipboard.writeText(content);
            setIsCopied(true);
            setTimeout(() => {
                setIsCopied(false);
            }, 2000);
        } catch (error) {
            console.error('Unable to copy to clipboard:', error);
        }
    };

    return (
        <div className='w-full border-2 rounded-xl bg-[#F9F9F9] border-[#E0E0E0]'>
            <div className='p-2 flex justify-between text-[13.78px]'>
                <input type='text' value={copyText} readOnly className='bg-[#F9F9F9] truncate ' />
                {
                    !isCopied
                        ?
                        <button onClick={() => handleCopy(copyText)} className='bg-primary-40 text-primary-100 px-3 py-1 rounded-xl'>
                            Copiar
                        </button>
                        : <span className='text-primary-40 font-bold px-3 py-1 '>¡Copiado!</span>
                }
            </div>
        </div>
    )
}

export default ClipboardCopy