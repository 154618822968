import ReactPlayer from 'react-player'

const VideoAbout = ({ url }: { url: string }) => {
    return (
        <div className='z-10'>
            <div className='w-[328px] h-[232px] sm:w-[472px] sm:h-[270px] border-2 border-primary-40'>
                <div className='p-2 w-full h-full'>
                    <ReactPlayer
                        url={url}
                        className='react-player'
                        width='100%'
                        height='100%'
                        controls={true}
                    />
                </div>
            </div>
            <div className='flex flex-col items-center'>
                <div className='w-[194px] h-[6.25px] sm:w-[279px] sm:h-[9px] border-2 border-primary-40'> </div>
                <div className='w-[16.68px] h-[31.26px] sm:w-[24px] sm:h-[45px] border-x-2 border-primary-40'></div>
                <div className='w-[93.12px] h-[6.95px] sm:w-[134px] sm:h-[10px] border-2 border-primary-40 rounded-t-lg'></div>
            </div>
        </div>
    )
}

export default VideoAbout