import { useState } from 'react';
import { IMediaAndContent } from '../../../interfaces/MediaAndContent'
import { Parser } from "html-to-react";
import { MdShare } from 'react-icons/md';
import Button from '../../../components/Buttons/Button';
import DateWithIcon from '../../../components/Dates/DateWithIcon';
import ChipsMapper from '../../../components/Mappers/ChipsMapper';
import DownloadPDF from '../../../components/DownloadPDF/DownloadPDF';
import Images from '../../../components/Images/Images';
import Dialog from '../../../components/Dialog/Dialog';
import Share from '../../../components/Share/Share';
import SEO from '../../../components/SEO/SEO';
const { REACT_APP_BACKOFFICE_CONNECTION, REACT_APP_BACKOFFICE_FILE_UPLOAD_PATH } = process.env;

const DetailOfMedia = ({ titulo, fecha, descripcion, categorias, imagen, imagen_base64, texto, pdf, pdf_base64 }: IMediaAndContent) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const href = window.location.href;

    const isMetodologia = window.location.pathname.includes('metodologia');

    return (
        <>
            <SEO
                title={titulo}
                description={descripcion}
                name='Monitor de derechos CABA'
                type='article'
                image={`${REACT_APP_BACKOFFICE_CONNECTION}${REACT_APP_BACKOFFICE_FILE_UPLOAD_PATH}${imagen}`}
                url={href}
            />
            <div className='flex flex-col gap-4 mt-6'>
                <h1 className='text-hmedium md:text-dsmall'>{titulo}</h1>
                <DateWithIcon date={fecha} />
                <ChipsMapper categorias={categorias} />
            </div>
            <div className='my-8 rounded-md  w-full' >
                <Images
                    imagen={imagen ?? null}
                    imagen_base64={imagen_base64 ?? null}
                    className={`${isMetodologia ? 'h-[200px]' : 'h-[224px] sm:h-[18.12rem] md:h-[464px] xl:h-[664px] w-full'} 
                    ${!imagen_base64 ? 'object-contain' : 'object-cover'} rounded-md`}
                />
            </div>
            <div className='flex flex-col gap-8'>
                <div className='text-blarge text-[#2C3134]'>
                    {Parser().parse(texto)}
                </div>
                <div className='flex gap-1 justify-end'>
                    {
                        pdf && pdf_base64 && pdf?.length > 0
                            ? <DownloadPDF base64String={pdf_base64} name={pdf} />
                            : <></>
                    }
                    <Button
                        label='Compartir'
                        as='link'
                        icon={MdShare}
                        className='text-system-light-40'
                        showIconLeft={true}
                        showIconRight={false}
                        onClick={() => setIsOpen(!isOpen)}
                    />
                </div>
            </div>
            <Dialog isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
                <Share
                    newsTitle={titulo}
                    newsUrl={href ? href : ''}
                    description={descripcion}
                />
            </Dialog>
        </>
    )
}

export default DetailOfMedia