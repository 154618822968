import React from 'react'

const Titles = ({ title }: { title: string }) => {
    return (
        <div className='bg-title-pattern bg-contain w-[9.375rem] h-[4.313rem]'>
            <div className='h-full flex items-center sm:w-max'>
                <h1 className='text-hmedium md:text-hlarge text-primary-10 pl-1 w-full sm:w-max'>{title}</h1>
            </div>
        </div>
    )
}

export default Titles