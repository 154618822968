import { createSlice } from '@reduxjs/toolkit'
import { IMediaAndContent } from '../../../interfaces/MediaAndContent'
import { getActivities, getDetailAndRelatedActivities, getCategories } from './activitiesThunk'
import { Pagination } from '../../../interfaces/Pagination'
import { ICategories } from '../../../interfaces/Rights';
import { CustomSelectsOption } from '../../../components/Inputs/Selects/CustomSelect';

interface ActivitiesState {
    activities: IMediaAndContent[] | null;
    majorActivities: IMediaAndContent[] | null;
    relatedActivities: IMediaAndContent[] | null;
    loading: "idle" | "pending" | "succeeded" | "failed";
    activityDetail: IMediaAndContent | null | undefined
    pagination: Pagination;
    categories: ICategories[] | null;
    categoriesOptions: CustomSelectsOption[] | null
}

const initialState: ActivitiesState = {
    loading: 'idle',
    activities: null,
    majorActivities: null,
    relatedActivities: null,
    pagination: { totalItems: 0, currentPage: 1, pageSize: 5, totalPages: 0 },
    activityDetail: null,
    categories: null,
    categoriesOptions: null
}

export const activitiesSlice = createSlice({
    name: 'activitie',
    initialState,
    reducers: {
        clearActivities: (state) => {
            state.activities = null;
            state.majorActivities = null;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getActivities.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getActivities.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.activities = action?.payload?.activities;
            //this activities are seen in the home
            state.majorActivities = action?.payload?.activities.slice(0, 3);
            state.pagination = action?.payload?.pagination
        });
        builder.addCase(getActivities.rejected, (state) => {
            state.loading = "failed";
        });
        builder.addCase(getDetailAndRelatedActivities.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getDetailAndRelatedActivities.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.relatedActivities = action.payload.related;
            state.activityDetail = action.payload.detail
        });
        builder.addCase(getDetailAndRelatedActivities.rejected, (state) => {
            state.loading = "failed";
        });
        builder.addCase(getCategories.pending, (state) => {
            state.loading = "pending";
        });
        builder.addCase(getCategories.fulfilled, (state, action) => {
            state.loading = "succeeded";
            state.categories = action.payload.categories
            //Options for dropdrown
            let categorieOption: CustomSelectsOption[] = action?.payload?.categories.map(({ titulo, id }) => ({ id: id, value: titulo }))
            state.categoriesOptions = [...categorieOption, { id: 'todas', value: 'Todas' }]
        });
        builder.addCase(getCategories.rejected, (state) => {
            state.loading = "failed";
        });
    },
})

export const { clearActivities } = activitiesSlice.actions;

export default activitiesSlice.reducer;