import { createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../../api/axiosInstance";
const { REACT_APP_API_PATH } = process.env;

export const getVideo = createAsyncThunk(
    "monitor/getVideo",
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.get(`${REACT_APP_API_PATH}/monitor/video`)
            const data = response.data
            return {
                video: data.data[0],
            }
        } catch (error: any) {
            return thunkAPI.rejectWithValue(error.response.data);
        }
    }
);